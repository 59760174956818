import { Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, Button } from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";

/**
 * @typedef {{
 *  jobs: object[],
 *  open?: boolean,
 *  onClose?: () => void,
 *  deleted?: boolean,
 *  future?: boolean,
 *  split?: boolean,
 *  shiftDate?: Date,
 *  shiftShift?: "A" | "I",
 * }} JobChangePopupProps
 */

/**
 * 
 * @param {JobChangePopupProps} props 
 * @returns 
 */
export default function JobChangePopup(props) {

    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        let njobs = [];
        const orderedJobs = props.jobs.toSorted((a, b) => 
            (new Date(a.date) - new Date(b.date)) ||
            (a.shift === "A" && b.shift === "I" ? -1 : a.shift === "I" && b.shift === "A" ? 1 : 0) ||
            (a.slot - b.slot)
        );
        orderedJobs.forEach((e) => {
            const njrow = njobs.find(y => format(y.date, "yyyy-MM-dd") === e.date && y.shift === e.shift);
            if (njrow) {
                njrow.jobs.push(e);
            }
            else {
                njobs.push({
                    date: props.shiftDate && e?.jobSplit?.date === format(props.shiftDate, "yyyy-MM-dd") ? new Date(e.jobSplit.date) : new Date(e.date),
                    shift: e?.jobSplit?.shift === props.shiftShift ? e.jobSplit.shift : e.shift,
                    jobs: [e]
                })
            }
        })

        setJobs(njobs);

    }, [props.jobs])
    
    const getShiftName = (shift) => {
        if (shift === "A") return "Aamu";
        if (shift === "I") return "Ilta";
        return "";
    }

    const getTitle = () => {
        if (!props.future || !jobs.length) return "Ajo-ohjelmasi on muuttunut!";
        let title = "Ajo-ohjelma muuttunut vuorolle:";
        title += " " + format(jobs[0].date, "dd.MM.yyyy");
        title += " " + getShiftName(jobs[0].shift);
        return title;
    }

    const renderSplitType = (job) => {
        let typeText = "";
        if (props.shiftDate && format(props.shiftDate, "yyyy-MM-dd") === job?.jobSplit?.date &&
            props.shiftShift === job?.jobSplit?.shift) {
            typeText = "Vienti vaihdosta";
        } else {
            typeText = "Haku vaihtoon";
        }
        if (job?.jobSplit?.id === null && props.split) {
            return <strong> (Jako poistettu)</strong>
        } else if (job?.jobSplit?.id) {
            return <strong> ({typeText})</strong>
        } else {
            return null;
        }
    }

    return <Dialog
        open={props.open}
        onKeyDown={(e) => {
            if (e.key === "Enter") {
                if (props.onClose) props.onClose();
            }
        }}
        maxWidth="md"
    >
        <DialogTitle>{getTitle()}</DialogTitle>
        <DialogContent>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{fontWeight: props.future ? "bold" : undefined}}>Pvm</TableCell>
                        <TableCell>Vuoro</TableCell>
                        <TableCell>Kuorma</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        jobs.map((e, i) => {
                            return e.jobs.map((ej, ij) => {
                                const itemAlias = ej.icItemAlias;

                                return <TableRow key={i + " " + ij}>
                                    <TableCell sx={{fontWeight: props.future ? "bold" : undefined}}>
                                        {ij === 0 ? format(e.date, "dd.MM.yyyy") : ""}
                                    </TableCell>
                                    <TableCell>{ij === 0 ? getShiftName(e.shift) : ""}</TableCell>
                                    <TableCell>
                                        {
                                            (() => {
                                                if (props.deleted) {
                                                    return (
                                                        <span>
                                                            <s>{ej.slot + 1} - {itemAlias ? itemAlias.name : ej.item.name} - {itemAlias ? itemAlias.cargoLabel : ej.item.cargoLabel}{renderSplitType(ej)}</s>
                                                            <i> (Poistettu)</i>
                                                        </span>
                                                    );
                                                } 
                                                else if (props.relocated && !props.split) {
                                                    return (
                                                        <span>
                                                            {ej.slot + 1} - {itemAlias ? itemAlias.name : ej.item.name} - {itemAlias ? itemAlias.cargoLabel : ej.item.cargoLabel}
                                                            <i> (Siirretty)</i>
                                                        </span>
                                                    );
                                                } 
                                                else {
                                                    return (
                                                        <span>
                                                            {ej.slot + 1} - {itemAlias ? itemAlias.name : ej.item.name} - {itemAlias ? itemAlias.cargoLabel : ej.item.cargoLabel}{renderSplitType(ej)}
                                                        </span>
                                                    );
                                                }
                                            })()
                                        }
                                    </TableCell>
                                </TableRow>
                            })
                        })
                    }
                </TableBody>
            </Table>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => { if (props.onClose) props.onClose() }} variant="contained">OK</Button>
        </DialogActions>
    </Dialog>
}