import { Box, Divider, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import LogitarApi, { APIPath } from "../../api/LogitarApi";
import { contentBoxStyle } from "../../styles/styles";
import { Loader } from '../../misc/InternalFeatures';
import ApiGenericDef from "./ApiGenericDef";
import ApiCallsDef from "./ApiCallsDef";

export const typeList = {
    "object": {
        name: "object",
        background: "lightblue",
        backgroundDark: "darkblue"
    },
    "enum": {
        name: "enum",
        background: "lightgreen",
        backgroundDark: "darkgreen"
    },
    "key": {
        name: "object key",
        background: "lightsteelblue",
        backgroundDark: "slateblue"
    }
}

export const methodList = {
    "GET": {
        name: "GET",
        background: "lightblue",
        backgroundDark: "darkblue"
    },
    "POST": {
        name: "POST",
        background: "lightgreen",
        backgroundDark: "darkgreen"
    }
}

export const extrasList = {
    "optional": {
        name: "Optional",
        background: "lightgrey",
        backgroundDark: "#444"
    },
    "array": {
        name: "Array",
        background: "lightyellow",
        backgroundDark: "#430"
        
    },
    "json": {
        name: "JSON",
        background: "lightblue",
        backgroundDark: "darkblue",
    },
    "enum": {
        name: "enum",
        background: "lightgreen",
        backgroundDark: "darkgreen"
    }
}

export const TypeBox = (props) => {
    return <Box sx={{ ml: 1, p: 0.5, borderRadius: 1, background: props.background }}>
        <Typography sx={{ fontSize: '0.7em' }}>{props.name}</Typography>
    </Box>
}

export default function ApiDoc() {

    const [doc, setDoc] = useState()
    const [open, setOpen] = useState(['calls', 'generics'])

    const [highlighted, setHighlighted] = useState(null);

    const [generics, setGenerics] = useState([])
    const [calls, setCalls] = useState([])

    const [filteredGenerics, setFilteredGenerics] = useState([]);
    const [filteredCalls, setFilteredCalls] = useState([]);

    const [search, setSearch] = useState("");


    useEffect(() => {
        LogitarApi.getApiDoc().then(result => {
            const apiGens = result.apidoc.generics;
            const apiCalls = result.apidoc.apis;

            let generics = [];
            let calls = [];

            for (let g in apiGens) {
                const attrs = Object.keys(apiGens[g]).filter(([key]) => key.includes('@') || key.includes('#'));
                generics.push({
                    name: g,
                    def: Object.fromEntries(Object.entries(apiGens[g]).filter(([key]) => !key.includes('@') && !key.includes('#'))),
                    attrs: attrs.reduce((acc, key) => (Object.assign(acc, { [key]: apiGens[g][key]})), {})
                })
            }

            for (let c in apiCalls) {
                calls.push({
                    name: c,
                    def: apiCalls[c]
                })
            }

            setGenerics(generics);
            setCalls(calls);
            setDoc(result.apidoc)
        })
    }, [])

    useEffect(() => {
        if(search === "") {
            setFilteredGenerics(generics);
            setFilteredCalls(calls);
            return;
        }
        setFilteredGenerics(generics.filter((e) => e.name.toLowerCase().includes(search.toLowerCase())));
        setFilteredCalls(calls.filter((e) => e.name.toLowerCase().includes(search.toLowerCase())));
    }, [search, doc])

    const handleCollapse = (index, forceOpen) => {

        if (forceOpen) {
            if (!open.includes(index)) {
                setOpen((prev) => [...prev, index])
            }
            return;
        }

        if (open.includes(index)) {
            setOpen((prev) => prev.filter((element) => { return element !== index }));
        } else {
            setOpen((prev) => [...prev, index]);
        }
    }

    if (!doc) {
        return <Loader />
    }

    return (
        <Box sx={{ ...contentBoxStyle, display: "flex", flexDirection: "column", m: 1 }}>
            <Box sx={{ display: "inherit", flexDirection: "row", mb: 1, justifyContent: 'space-between' }}>
                <Box sx={{ display: "inherit", flexDirection: 'row' }}>
                    <Typography variant="h5">{doc.title} - {doc.company}</Typography>
                </Box>
                <TextField 
                    placeholder={"Search"} 
                    value={search}
                    label={"Search"}
                    onChange={(e) => setSearch(e.target.value)}
                />
            </Box>

            <Typography>Palvelun URL: <span style={{fontWeight: 'bold'}}>{APIPath}/</span></Typography>
            <Typography variant="body1" sx={{mt: 1}}>
                Palveluun voi tehdä kutsuja alla olevien API-kutsujen avulla. Jokainen kutsu toteutetaan HTTP(s) GET tai POST -metodilla.
                Jokaisen kutsun täytyy sisältää API-avain. GET-kutsuissa on usein määritetty 'extent'-parametri, joka määrittää vastauksen laajuuden.
                Eri laajuudella tulevan vastauksen formaatti on määritelty alla olevassa dokumentaatiossa.
                <br/><br/>
                Jokaisen API-kutsun vastaus on JSON-muodossa.
                API palauttaa jokaisessa viestissä 'status'-kentän, joka kertoo pyynnön onnistumisen boolean-arvona. Jos 'status'-kenttä on false, palauttaa API myös 
                'error'-kentän, joka sisältää virhekoodin. Tietyissä virhetapauksissa API saattaa palauttaa sisäisen virheen, jolloin vastaus ei ole JSON-muodossa.
                <br/><br/>
                Eri yritysten tiedot löytyvät eri järjestelmistä, joiden osoite on seuraavassa muodossa: <span style={{fontWeight: 'bold'}}>https://api.YRITYS.logitar.fi/</span>

            </Typography>

            <Box>
                <Box sx={{ display: "flex", flexDirection: "row", mb: 1, p: 1 }}>
                    <Typography variant="h6">API</Typography>
                </Box>
                <Box sx={{ display: "inherit", flexDirection: "inherit" }}>
                    <Divider sx={{ m: 1 }} />
                    {

                        filteredCalls.map((e, i) => {
                            return <ApiCallsDef
                                key={i}
                                name={e.name}
                                def={e.def}
                                generics={generics}
                                collapsed={!open.includes(e.name)}
                                onCollapse={() => handleCollapse(e.name)}
                                linkOpen={(name) => { handleCollapse(name, true); setHighlighted(name) }}
                                highlighted={highlighted === e.name}
                            />
                        })

                    }
                </Box>
            </Box>
            <Divider sx={{ m: 3 }} />
            <Box>
                <Box sx={{ display: "flex", flexDirection: "row", mb: 1, p: 1 }}>
                    <Typography variant="h6">Generic types</Typography>
                </Box>
                <Box sx={{ display: "inherit", flexDirection: "inherit" }}>
                    <Divider sx={{ m: 1 }} />
                    {
                        filteredGenerics.map((e, i) => {
                            return <ApiGenericDef
                                key={i}
                                name={e.name}
                                def={e}
                                generics={generics}
                                collapsed={!open.includes(e.name)}
                                onCollapse={() => handleCollapse(e.name)}
                                linkOpen={(name) => { handleCollapse(name, true); setHighlighted(name) }}
                                highlighted={highlighted === e.name}
                            />
                        })
                    }
                </Box>
            </Box>

        </Box>
    )

}