import { Box, Button, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, FormGroup, Popover, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography, useTheme, Switch } from '@mui/material'
import React, { useState, useEffect, useRef } from 'react'
import LogitarApi from '../api/LogitarApi'
import { ReadPermissionInfo, WritePermissionInfo, WritePermissions } from '../misc/Permissions'
import LogiTarUser, { LogiTarUserType, LogiTarUserTypeInfo } from '../misc/User'

import { ArrowDropDown, Close, DeleteForeverOutlined, Save } from '@mui/icons-material'

//import MultiTreeSelect, { MultiTreeSelectItem } from './MultiTreeSelect'
import MultiTreeSelect from './MultiTreeSelect.tsx'
import PopupScreen from './PopupScreen'
import { useSnackbar } from 'notistack'
import UserClientItemEdit from './UserClientItemEdit'
import getRoutes from '../misc/Routes/Routes'
import Config from '../config/Config'
import { UserConfig } from '../misc/UserConfig'

/* global BigInt */


const formStyle = {
    width: "100%"
}

export const emailNotificationTypes = [
    {
        id: "label:Huoltoseuranta", label: "Huoltoseuranta", options: [
            { id: "serviceOrdered", label: "Huolto tilattu" },
            { id: "inspectionSoon", label: "Katsastusmuistutus" }
        ]
    },
    {
        id: "label:Huoltotapahtuma", label: "Huoltotapahtuma", options: [
            { id: "serviceSoon", label: "Huoltomuistutus" },
            { id: "serviceEventOrdered", label: "Huolto tilattu" }

        ]
    }
]

const findEmailNotificationType = (id, options = emailNotificationTypes) => {
    for (let opt of options) {
        if (opt.options) {
            const nt = findEmailNotificationType(id, opt.options);
            if (nt) {
                return nt;
            }
        }
        if (opt.id === id) {
            return opt;
        }
    }
}


export default function UserEdit(props) {

    const [user, setUser] = useState((props.new) ? { ...props.user } : { ...props.user, password: '*****' })
    const [postUser, setPostUser] = useState((!props.new) ? { id: props.user.id } : { ...props.user })

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [vehicleSelectOpen, setVehicleSelectOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [vehicles, setVehicles] = useState([]);
    const [selectedVehicles, setSelectedVehicles] = useState([]);
    const [emailFlagsOpen, setEmailFlagsOpen] = useState(false);
    const [selectedEmailFlags, setSelectedEmailFlags] = useState([]);

    const [changed, setChanged] = useState({})

    const [showUserClientItemEdit, setShowUserClientItemEdit] = useState(false);

    const theme = useTheme();

    const [saveMode, setSaveMode] = useState(null);
    const formRef = useRef();
    const rowStyle = { display: "flex", flexDirection: "row" }

    const showUserCostCentre = Config.getBranch() === "tarkkala" || Config.getBranch() === "softrain";

    const profileMode = props.profileMode ? props.profileMode : false;
    const [showConfigDelete, setShowConfigDelete] = useState(UserConfig.hasSettings());

    const handleChange = (event) => {

        if (event.target.name === "") {
            // Unknown target
            return;
        }

        // Handle id change to only affect newId
        if (event.target.name === "id") {
            let val = { newId: event.target.value };
            setChanged(prev => ({ ...prev, newId: true }));
            setUser(prev => ({ ...prev, ...val }));
            setPostUser(prev => ({ ...prev, ...val }));
            return;
        }

        let val = { [event.target.name]: event.target.value };

        console.log(event.target.name, event.target.value, event.target.checked, event.target.type)

        if (event.target.name === "permissions") {
            const v = event.target.checked ? (BigInt(user.permissions) | BigInt(event.target.value)) : (BigInt(user.permissions) & ~BigInt(event.target.value))
            val['permissions'] = v.toString()
        } 
        else if (event.target.name === "passive") {
            val['passive'] = event.target.checked ? 0 : 1;
        }
        else if (event.target.type === "checkbox") {
            val[event.target.name] = Number(event.target.checked);
        }
        else if (event.target.name === "allowContractorReport") {
            val['allowContractorReport'] = event.target.checked ? 1 : 0;
        }
        else {
            if (event.target.name === "userType") {
                let uinfo = LogiTarUserTypeInfo.find((e) => e.userType === parseInt(event.target.value))
                if (uinfo) {
                    val = { ...val, permissions: uinfo.permissions.toString() }
                }
                console.log(val)
            }
        }

        setChanged(prev => ({ ...prev, [event.target.name]: true }))
        setUser(prev => ({ ...prev, ...val }))
        setPostUser(prev => ({ ...prev, ...val }))
    }

    useEffect(() => {
        if (props.user && props.user.emailFlags) {
            const ef = JSON.parse(props.user.emailFlags);
            setSelectedEmailFlags(ef.map(e => {
                const ed = findEmailNotificationType(e);
                return ed ? ed : null;
            }).filter(e => e !== null));
        }
        if (props.user && props.user.vehicles) {
            setSelectedVehicles(vehicles.filter(e => user.vehicles.includes(e.id)).map((e) => {
                return {
                    id: e.id,
                    label: e.id + ":" + e.licenseNumber,
                }
            }));
        }
    }, [props.user]);

    useEffect(() => {

        if ((Number(user.userType) !== LogiTarUserType.DRIVER && Number(user.userType) !== LogiTarUserType.CONTRACTOR) || vehicles.length > 0) {
            return
        }

        LogitarApi.getVehicles()
            .then((data) => {


                let nvehicles = data.vehicles.filter((v) => v.type === 1);

                setVehicles(nvehicles.map((e) => {
                    return {
                        id: e.id,
                        label: e.id + ":" + e.licenseNumber,
                    }
                }));

                if (!props.new) {
                    // Set checked
                    setSelectedVehicles(nvehicles.filter(e => user.vehicles.find(y => y.id === e.id)).map((e) => {
                        return {
                            id: e.id,
                            label: e.id + ":" + e.licenseNumber,
                        }
                    }));

                } else {
                    setSelectedVehicles([]);
                }
            })
            .catch((e) => { console.error(e) })
    }, [user.userType])

    //Wrap functions here to reduce Button clutter
    const handleUpdate = () => {

        setSaveMode(true);
        if (checkSaveIsValid() == false) {
            enqueueSnackbar("Täytä kaikki pakolliset kentät", { variant: "error" })
            return;
        }

        let newpostuser = postUser;
        //Remove dummy ***** password
        if (!changed['password']) {
            const { password, ...rest } = postUser;
            newpostuser = { ...rest };
        }

        // Null int costCentre if field is cleared
        if (newpostuser?.costCentre != null && newpostuser.costCentre === "") {
            newpostuser.costCentre = null;
        }
        
        // Delete driver exclusive fields when saving non-driver users
        if (Number(user.userType) !== LogiTarUserType.DRIVER) {
            delete newpostuser.driverID;
            delete newpostuser.costCentre
        }

        LogitarApi.setUser(newpostuser).then((result) => {
            console.log(result)
            if (props.rowUpdate) {
                props.rowUpdate(prev => prev + 1)
            }
            props.onClick(false)
            enqueueSnackbar("Käyttäjä tallennettu")

            if (profileMode) {
                LogiTarUser.current.info = { ...LogiTarUser.current.info, ...newpostuser };
            }
        })
        .catch((e) => {
                console.error(e)
                if (e.dberr) {
                    if (e.dberr.includes("username_UNIQUE")) {
                        enqueueSnackbar("Käyttäjän tallentaminen epäonnistui. Käyttäjätunnus on varattu.", { variant: 'error' });
                    }
                    else if (e.dberr.includes("Duplicate") && e.dberr.includes("PRIMARY")) {
                        enqueueSnackbar("Käyttäjän tallentaminen epäonnistui. Työntekijänumero on varattu.", { variant: "error" });
                    }
                    else {
                        enqueueSnackbar("Käyttäjän tallentaminen epäonnistui.", { variant: "error" });
                    }
                }
                else {
                    enqueueSnackbar("Käyttäjän tallentaminen epäonnistui.", { variant: "error" });
                }
            })
    }

    const CarsButtonTooltipContent = () => {

        console.log(user)

        if (vehicles.length === 0)
            return null;

        return <div style={{ padding: 4 }}>
            <span >Valitut autot:</span>
            {
                vehicles.filter((v) => v.checked === 'checked').map((e, i) => {
                    return <div style={{ paddingLeft: 16 }}>
                        <li key={i}>{e.label}</li>
                    </div>
                })
            }
        </div>
    }

    const disablePerms = !LogiTarUser.current.checkPermission(WritePermissions.PERM_SETUSERS)

    const checkIsEmpty = (field, value) => {
        if (props.new == true && saveMode == null) return false;
        if (!value) {
            return true;
        }
        if (value && value.length < 1) {
            return true;
        }
        if (field == "userType" && value == 0) {
            return (true)
        }
        return false;
    };

    const checkSaveIsValid = () => {
        if (user.userType == null || user.userType.length < 1 || user.userType == 0) return false;
        if (user.username == null || user.username.length < 1) return false;
        if ((user.password == null || user.password.length < 1) && changed['password']) return false;
        if (user.name == null || user.name.length < 1) return false;
        if (user.email == null || user.email.length < 1) return false;
        return true;
    }

    return (
        <PopupScreen
            title={!props.new ? profileMode ? "Profiilin Muokkaus" : "Käyttäjä Muokkaus" : "Uusi Käyttäjä"}
            onClose={() => props.onClick(false)}
            onSave={() => handleUpdate()}
            draggable
        >
            <form onChange={handleChange} ref={formRef}>
                <FormGroup row sx={{ justifyContent: "space-between", mr: 1 }}>
                <Box sx={{ ...rowStyle, alignItems: "center", "& .MuiTypography-root": { mx: 1 } }}>
                    <Typography>Passiivinen</Typography>
                    <Switch value={!user.passive} checked={Boolean(!user.passive)} onChange={handleChange} name="passive"></Switch>
                    <Typography>Aktiivinen</Typography>
                </Box>
                {
                    (profileMode && showConfigDelete) &&
                    <Tooltip title={"Poistaa kaikki selaimeen tallennetut asetukset"}>
                        <Button onClick={() => {
                            UserConfig.deleteSettings();
                            setShowConfigDelete(false);
                            if (!UserConfig.hasSettings()) enqueueSnackbar("Asetukset poistettu", { variant: 'success' });
                        }} variant='outlined' color='error' startIcon={<DeleteForeverOutlined/>}>Poista kaikki asetukset</Button>
                    </Tooltip>
                }
                </FormGroup>

                <FormControl
                    sx={{
                        '& .MuiTextField-root': { mr: 1, mb: 1.5, width: "100%" },
                        '& .MuiFormGroup-root': { display: "inline-flex", flexWrap: "nowrap", justifyContent: "space-between" },
                        maxHeight: '90vh', width: '100%', minWidth: 900, paddingBottom: 8, 
                        pt: 3
                    }}>
                    <FormGroup row>
                        <FormControl sx={formStyle}>
                            <FormGroup row={false}>
                                <FormGroup row>
                                    <TextField
                                        label="Työntekijänumero"
                                        placeholder='Tyhjänä luodaan automaattisesti'
                                        name="id"
                                        value={user.newId ? user.newId : user.id}
                                        type="number"
                                        style={{ colorScheme: theme.palette.mode === 'light' ? "light" : "dark" }}
                                        disabled={disablePerms}
                                    />
                                    <TextField
                                        select
                                        label="Taso"
                                        name="userType"
                                        SelectProps={{
                                            native: true,
                                        }}
                                        required aria-required
                                        value={user.userType}
                                        //error={Number(user.userType) === 0 && changed['userType']}
                                        error={checkIsEmpty("userType", user.userType)}
                                        helperText={Number(user.userType) === 0 && changed['userType'] ? "Valitse käyttäjän taso" : ""}
                                        disabled={disablePerms}
                                    >
                                        {
                                            LogiTarUserTypeInfo.map((option) => (
                                                <option key={"sel-opt-" + option.userType} value={option.userType}>
                                                    {option.label}
                                                </option>
                                            ))
                                        }
                                    </TextField>
                                </FormGroup>
                                <FormGroup row>
                                    <TextField
                                        label="Käyttäjätunnus"
                                        name="username"
                                        autoComplete='off'
                                        required aria-required
                                        value={user.username}
                                        //error={user.username.length < 1 && changed['username']}
                                        error={checkIsEmpty("username", user.username)}
                                        helperText={user.username.length < 1 && changed['username'] ? "Käyttäjänimi ei voi olla tyhjä" : ""}
                                        onInput={(e) => {
                                            e.target.value = e.target.value.toString().slice(0, 20)
                                        }}
                                    />
                                    <TextField
                                        type={'password'}
                                        label="Salasana"
                                        name="password"
                                        required aria-required
                                        value={user.password}
                                        //error={(!user.password || user.password.length < 1) && (props.new || changed['password'])}
                                        error={checkIsEmpty("password", user.password)}
                                        //helperText={(!user.password || user.password.length < 1) && (props.new || changed['password']) ? "Salasana ei voi olla tyhjä" : ""}
                                        helperText={user.password.length < 1 && changed['password'] ? "Salasana ei voi olla tyhjä" : ""}
                                        inputProps={{
                                            list: 'autocompleteOff',
                                            autoComplete: 'new-password',
                                            list: 'autocompleteOff'
                                        }}
                                    />
                                </FormGroup>

                                <FormGroup row>
                                    <TextField
                                        label="Nimi"
                                        name="name"
                                        required aria-required
                                        value={user.name}
                                        //error={user.name.length < 1 && changed['name']}
                                        error={checkIsEmpty("name", user.name)}
                                        helperText={user.name.length < 1 && changed['name'] ? "Nimi ei voi olla tyhjä" : ""}
                                    />
                                    <TextField
                                        label="Sähköpostiosoite"
                                        name="email"
                                        value={user.email}
                                        required aria-required
                                        error={checkIsEmpty("email", user.email)}
                                    />
                                    <TextField
                                        label="Puhelinnumero"
                                        name="phoneNumber"
                                        value={user.phoneNumber}
                                    />
                                </FormGroup>
                                {
                                    Number(user.userType) === LogiTarUserType.DRIVER &&
                                    <FormGroup row>
                                        <TextField
                                            label="Kuljettajan ID"
                                            name="driverID"
                                            value={user.driverID}
                                            sx={{flex: 2.0125}} // Doesn't align perfectly with just 2:1
                                        />
                                        {
                                            showUserCostCentre && 
                                            <TextField
                                            label="Oma tulospaikka"
                                            name="costCentre"
                                            value={user.costCentre}
                                            inputProps={{maxLength: 5}}
                                            sx={{flex: 0.9875}}
                                        />
                                        }
                                    </FormGroup>
                                }

                                <FormGroup row>
                                    {
                                        ((Number(user.userType) === LogiTarUserType.DRIVER || Number(user.userType) === LogiTarUserType.CONTRACTOR) && !disablePerms) &&
                                        <>
                                            <Tooltip title={<CarsButtonTooltipContent />}>
                                                <Button
                                                    onClick={(e) => { setVehicleSelectOpen(true); setAnchorEl(e.currentTarget) }}

                                                >
                                                    Autot
                                                    {
                                                        vehicles.length === 0 &&
                                                        <CircularProgress size={15} />
                                                    }
                                                    {
                                                        vehicles.length > 0 &&
                                                        <ArrowDropDown />
                                                    }
                                                </Button>
                                            </Tooltip>
                                            <MultiTreeSelect
                                                open={vehicleSelectOpen}
                                                options={vehicles}
                                                value={selectedVehicles}
                                                anchorEl={anchorEl}
                                                onClose={() => { setAnchorEl(null); setVehicleSelectOpen(false) }}
                                                id='vehicles'
                                                onChange={(value) => {
                                                    setSelectedVehicles(value);
                                                    setPostUser({ ...postUser, vehicles: value.map(e => e.id) });
                                                }}
                                            />
                                        </>
                                    }
                                    {
                                        // Email notification settings
                                        Number(user.userType) !== LogiTarUserType.DEVELOPER &&
                                        <>
                                            <Button
                                                onClick={(e) => { setEmailFlagsOpen(true); setAnchorEl(e.currentTarget) }}

                                            >
                                                Sähköpostihälytykset
                                                <ArrowDropDown />
                                            </Button>
                                            <MultiTreeSelect
                                                open={emailFlagsOpen}
                                                options={emailNotificationTypes}
                                                value={selectedEmailFlags}
                                                anchorEl={anchorEl}
                                                onClose={() => { setAnchorEl(null); setEmailFlagsOpen(false) }}
                                                id='emailFlags'
                                                onChange={(value) => {
                                                    setSelectedEmailFlags(value);
                                                    setPostUser({ ...postUser, emailFlags: JSON.stringify(value.map(e => e.id)) });
                                                }}
                                            />
                                        </>
                                    }
                                    {
                                        Number(user.userType) === LogiTarUserType.CLIENT &&
                                        <Box sx={{ flexDirection: 'row' }}>
                                            <Button
                                                onClick={() => setShowUserClientItemEdit(true)}
                                            >
                                                Asiakkaat & nimikkeet
                                            </Button>
                                        </Box>
                                    }
                                    <Box sx={{ flexDirection: 'row' }}>                                                                            
                                        {Number(user.userType) === LogiTarUserType.DRIVER && 
                                        <>
                                            <Typography variant='caption'>Salli edellisen jakson tuntikirjaus:</Typography>
                                            <Checkbox
                                                name='allowHoursWrite'
                                                onChange={handleChange}
                                                checked={Number(user.allowHoursWrite)}
                                            />
                                        </>
                                        }
                                    </Box>
                                </FormGroup>

                                <Divider />

                                <Typography variant='caption'>Oikeudet</Typography>
                                <FormGroup row>

                                    <Table
                                        size='small'
                                        sx={{
                                            '& .MuiTableCell-root': { padding: 0 },
                                            '& .MuiCheckbox-root': { padding: 0 }
                                        }}
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ width: "40%" }}></TableCell>
                                                <TableCell>Näkymä</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                Object.keys(getRoutes(Number(user.userType))).filter(e => e !== "_hidden").map((e, i) => {

                                                    //Get actual routes under the category
                                                    const category = getRoutes(Number(user.userType))[e];

                                                    //Filter out routes that user does not have permission to
                                                    const headers = category.map(r => {
                                                        if (BigInt(r.perms & BigInt(user.permissions)) === BigInt(0)) {
                                                            return null;
                                                        }
                                                        return r
                                                    }).filter(e => e !== null);

                                                    //No routes, no reason to show headers
                                                    if (headers.length === 0) {
                                                        return null;
                                                    }

                                                    console.log("Kategoria ", category)

                                                    return <React.Fragment key={i}>
                                                        <TableRow key={"ROUTES_" + e}>
                                                            <TableCell sx={{ fontWeight: "bold" }}>{e}</TableCell>
                                                            <TableCell />
                                                        </TableRow>
                                                        {
                                                            category.map(r => {
                                                                return <TableRow key={r.path}>
                                                                    <TableCell />
                                                                    <TableCell>
                                                                        {r.header}
                                                                    </TableCell>
                                                                    { (r.header == 'Alihankintaraportit' && user.userType == 2) && (
                                                                        <TableCell>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox sx={{ ml: 3 }} 
                                                                                    name="allowContractorReport"
                                                                                    checked={user.allowContractorReport}
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Typography sx={{ fontSize: '12px', ml:1}}>
                                                                                    Näytä alihankkijalle
                                                                                </Typography>
                                                                            }                                             
                                                                        />
                                                                        </TableCell>
                                                                    )}

                                                                </TableRow>
                                                            })
                                                        }
                                                    </React.Fragment>
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </FormGroup>
                            </FormGroup> 
                        </FormControl>
                    </FormGroup>
                </FormControl>
            </form>
            {
                showUserClientItemEdit &&
                <UserClientItemEdit
                    user={user}
                    onClose={() => setShowUserClientItemEdit(false)}
                    onSave={(clients, items) => {
                        setUser({ ...user, clients: clients, items: items });
                        setPostUser({ ...postUser, clients: clients, items: items });
                        setShowUserClientItemEdit(false);
                    }}
                />
            }
        </PopupScreen>
    )
}