import { format } from "date-fns";
import { GetPlanningRowIC } from "../api/LogitarApiTypes";
import { PlanningJobInfo, PlanningRowIC, PlanningSlot } from "./LogitarTypes";
import { convertShift, PLANNING_SLOT_INITIAL_COUNT } from "../views/Planning";

export function createInterClientRows(ics: GetPlanningRowIC[], date: Date, shift: boolean) {
    const _ics: PlanningRowIC[] = [];
    ics.forEach((ic) => {
        const row: PlanningRowIC = {
            client: ic.client,
            shiftInfo: {
                date: format(date, "yyyy-MM-dd"),
                shift: convertShift(shift)
            },
            slots: []
        };

        const icCount = ic?.jobs?.length || 0;
        const icSlotCount = Math.max(PLANNING_SLOT_INITIAL_COUNT, icCount + 1);

        for (let i = 0; i < icSlotCount; i++) {
            const slot: PlanningSlot = {
                date: format(date, "yyyy-MM-dd"),
                shift: convertShift(shift),
                slot: i,
                vehicle: 0,
                job: null
            };
            const existingJob = ic.jobs[i];
            if (existingJob) {
                if (existingJob?.draft && existingJob.draft?.planDetails) {
                    existingJob.planDetails = existingJob.draft.planDetails;
                }
                slot.job = existingJob as unknown as PlanningJobInfo;
            }
            row.slots.push(slot);
        }

        _ics.push(row);
    })
    return _ics;
}

export function interClientRowToJobs(row: PlanningRowIC): PlanningJobInfo[] {
    const jobs: PlanningJobInfo[] = [];
    row?.slots?.forEach(slot => {
        if (slot.job !== null) {
            jobs.push(slot.job);
        }
    })
    return jobs;
}