import { Autocomplete, Box, Divider, Stack, SxProps, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography, useTheme } from "@mui/material";
import { format, isAfter, isBefore } from "date-fns";
import { useEffect, useState } from "react";
import LogitarApi from "../api/LogitarApi";
import DragBox from "../components/DragBox";
import { logoPathsSmall } from "../components/PlanningCarRow";
import { Loader } from "../misc/InternalFeatures";
import { PlanningItem, PlanningJobInfo, PlanningOrder } from "../misc/LogitarTypes";
import LogitarEventProvider from "../api/LogitarEventProvider";

const ordersStyle: SxProps = {
    display: "flex",
    flexWrap: "wrap",
    border: 1,
    borderColor: "#cccccc",
    p: 0.25,
    maxHeight: "100%",
    width: "auto",
    minWidth: "150px",
    borderRadius: 1,
    mb: 1,
    overflow: "auto"
}

type PlanningOrderListProps = {
    date: Date,
    shift: 'A' | 'I',
    unassigned: any[],
    onDragStart: (e: DragEvent, item: PlanningItem, order?: PlanningOrder) => void,
    onDragStartIC?: (e: DragEvent, job: PlanningJobInfo) => void,
    events?: {source: string, data: any}[],
    onDragEnd: () => void
}

export const CreateGroupedOrderList = (orders: any[]) => {
    // Must group orders by item
    let ords: any[] = [];
    for (let order of orders) {
        order.details = order.details.map((d: string) => <li>{d}</li>);
        ords.push(order);
    }
    // Sort orders by client name
    ords.sort((a, b) => a.item.clientName.localeCompare(b.item.clientName));
    return ords;
}

export default function PlanningOrderList(props: PlanningOrderListProps) {

    const [mode, setMode] = useState<'orders' | 'items'>('orders');
    const [ordersLoading, setOrdersLoading] = useState<boolean>(true);
    const [itemsLoading, setItemsLoading] = useState<boolean>(true);

    const [events, setEvents] = useState<{ source: string, data: any, id: number }[]>([]);
    const [count, setCount] = useState<number>(0);

    // Orders/clients/items are fetched in-component...
    const [orders, setOrders] = useState<any[]>([]);
    const [clientsOrders, setClientsOrders] = useState<any[]>([]);
    const [clients, setClients] = useState<any[]>([]);
    const [filteredClients, setFilteredClients] = useState<any[]>([]);
    const [items, setItems] = useState<any[]>([]);
    const [clientsItems, setClientsItems] = useState<any[]>([]);
    // ...while unassigned jobs come from props
    const [unassigned, setUnassigned] = useState<any[]>([]);

    const [selectedClient, setSelectedClient] = useState<{ id: number, label: string } | null>(null);

    const theme = useTheme();

    useEffect(() => {
        setUnassigned(props.unassigned);
    }, [props.unassigned])

    useEffect(() => {
        // Fetch orders
        //setOrdersLoading(true);

        LogitarApi.getOrders({ date: format(props.date, "yyyy-MM-dd"), combined: true}).then((result) => {

            const ords = CreateGroupedOrderList(result.orders);
            console.log("Saadut tilaukset ", ords)
            setOrders(ords);
            setOrdersLoading(false);
            if(selectedClient) {
                setClientsOrders(ords.filter((o: any) => o?.item?.client == selectedClient.id));
            }
            else {
                setClientsOrders(ords);
            }
        })
            .catch((err) => {

            })

    }, [props.date, props.shift, count])

    useEffect(() => {
        // Fetch clients, items
        setItemsLoading(true);
        LogitarApi.getClients().then((r) => {
            const cli = r.clients.map((option: any) => ({ id: option.id, label: option.name }));
            setClients(cli.sort((a: {id: number, label: string}, b: {id: number, label: string}) => a.label.localeCompare(b.label)));
        })
            .catch((err) => console.error(err))

        LogitarApi.getItems().then((r) => {
            setItems(r.items);
            setItemsLoading(false);
            setClientsItems(r.items);
        })
            .catch((err) => console.error(err))
    }, [])

    useEffect(() => {
        if (selectedClient) {
            setClientsItems(items.filter((i) => i.client == selectedClient.id));
            setClientsOrders(orders.filter((o: any) => o?.item?.client == selectedClient.id));
        }
        else {
            setClientsItems(items);
            setClientsOrders(orders);
        }
    }, [selectedClient])

    // Handle events
    useEffect(() => {
        if (events.length === 0)
            return;
        setCount(count + 1);
        setEvents([]);
    }, [events])

    useEffect(() => {
        if (mode === 'orders') {
            const orderActive = (order: any) => true;
            setFilteredClients(clients.filter((c) => orders.find((o) => o?.item?.client == c.id && orderActive(o))));
        }
        else {
            setFilteredClients(clients.filter((c) => items.find((i) => i.client == c.id)));
        }
    }, [mode, orders, clients]);


    return (
        <Box>
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    mt: 1
                }}
            >
                <ToggleButtonGroup
                    value={mode}
                    onChange={(e, v) => {
                        if (v) {
                            setMode(v);
                        }
                    }}
                    exclusive
                    sx={{
                        ".MuiToggleButton-root": {
                            padding: 1
                        },
                        width: "100%", // Set the width to 100%
                    }}
                    fullWidth
                >
                    <ToggleButton size="small" value={'orders'}>Tilaukset</ToggleButton>
                    <ToggleButton size="small" value={'items'}>Nimikkeet</ToggleButton>
                </ToggleButtonGroup>
            </Box>
            <Autocomplete
                options={filteredClients}
                value={selectedClient}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.label}
                onChange={(e, v) => {
                    setSelectedClient(v);
                }}
                renderInput={(params) => (<TextField {...params} label="Asiakaskoodi" />)}
                size="small"
                sx={{
                    my: 1
                }}
            />
            {
                mode === 'orders' &&
                <Box component={'fieldset'} sx={{ ...ordersStyle, ...(theme.palette.mode === "dark" ? { borderColor: "#555" } : {}) }}>
                    <legend style={{ filter: 'opacity(60%)', fontSize: '0.75rem', fontWeight: 400 }}>Tilaukset</legend>
                    {
                        !ordersLoading ?
                            [
                                clientsOrders.map((value, index) => {
                                    if(value.ordersLeft === 0)
                                        return null;

                                    return (
                                        <DragBox
                                            key={"dbo" + index}
                                            id={`${value.item.id}`}
                                            number={`${value.ordersLeft}`}
                                            text={`${value.item.id}:${value.item.shortName} ${value.item.cargoLabel}`}
                                            //subtext={`${value.details}`}
                                            tooltip={value.details.length > 0 ? value.details : null}
                                            dragStart={(ev: DragEvent) => {
                                                const item: Partial<PlanningItem> = {
                                                    id: value.item.id,
                                                    client: value.item.client,
                                                    itemNumber: value.item.itemNumber,
                                                    name: value.item.name,
                                                    cargoType: value.item.cargoType,
                                                    cargoCode: value.item.cargoCode,
                                                    cargoDivision: value.item.cargoDivision,
                                                    cargoLabel: value.item.cargoLabel,
                                                    clientName: value.item.clientName,
                                                    interClients: value.item.interClients,
                                                }
                                                props.onDragStart(ev, item as PlanningItem, value);
                                            }}
                                            dragEnd={props.onDragEnd}
                                            size={'100%'}
                                            className={
                                                ((value.details.length > 0) ? "default-green" : "") +
                                                (value.ordersLeft < 0 ? " default-red-text" : "")
                                            }
                                            onClick={() => {
                                                window.open(`orders?itemId=${value.item.id}`, "_blank");
                                            }}
                                        />
                                    )
                                }),
                                <RenderUnassigned
                                    key="unassinged"
                                    unassigned={unassigned}
                                    date={props.date}
                                    onDragStartIC={props.onDragStartIC}
                                    onDragEnd={props.onDragEnd}
                                />
                            ]
                            : <Loader />
                    }


                </Box>
            }
            {
                mode === 'items' &&
                <Box component={'fieldset'} sx={{ ...ordersStyle, ...(theme.palette.mode === "dark" ? { borderColor: "#555" } : {}) }}>
                    <legend style={{ filter: 'opacity(60%)', fontSize: '0.75rem', fontWeight: 400 }}>Nimikkeet</legend>
                    <Box sx={{ p: 1, display: "flex", flexWrap: "wrap", overflow: "auto", maxHeight: "90%" }}>
                        {

                            clientsItems &&
                            clientsItems.map((item, index) => (
                                <DragBox
                                    key={"dbc" + index}
                                    id={item.id}
                                    text={`${item.id}:${item.shortName} ${item.cargoLabel}`}
                                    subtext={""}
                                    dragStart={(ev: DragEvent) => props.onDragStart(ev, item, undefined)}
                                    size={'100%'}
                                    onClick={() => {
                                        window.open(`items?id=${item.id}` + (!item.active ? "&passive" : ""), "_blank");
                                    }}
                                    dragEnd={props.onDragEnd}
                                    passive={!item.active}
                                />
                            ))
                        }
                    </Box>
                </Box>
            }
            <LogitarEventProvider
                subscriptions={["job", "icjob", "jobsplit"]}
                onEvent={(source, data, id) => {
                    setEvents(prev => [...prev, { source: source, data: data, id: id }]);
                }}
                onConnect={() => setCount(Date.now())}
            />
        </Box>
    )
}

type RenderUnassignedProps = {
    unassigned: {
        client: {
            name: string,
            displayName: string
        },
        jobs: Partial<PlanningJobInfo & { copies: number }>[]
    }[],
    date: Date,
    onDragStartIC?: (e: DragEvent, job: PlanningJobInfo) => void,
    onDragEnd: () => void,
}

function RenderUnassigned(props: RenderUnassignedProps) {
    const [unassigned, setUnassigned] = useState(props.unassigned);

    useEffect(() => {
        setUnassigned(props.unassigned);
    }, [props.unassigned]);

    // Filter jobs to current, previous and future based on date
    const current = unassigned.map(ua => ({
        client: ua.client,
        jobs: ua.jobs.filter(uaj => format(new Date(props.date), "yyyy-MM-dd") === uaj.date)
            .sort((a, b) => (a.id && b.id) ? a.id - b.id : 0)
    }));
    const previous = unassigned.map(ua => ({
        client: ua.client,
        jobs: ua.jobs.filter(uaj => isBefore(new Date(uaj.date || 0), new Date(props.date).setHours(0, 0, 0)))
            .sort((a, b) => (a.id && b.id) ? a.id - b.id : 0)
    }));
    const future = unassigned.map(ua => ({
        client: ua.client,
        jobs: ua.jobs.filter(uaj => isAfter(new Date(uaj.date || 0), new Date(props.date).setHours(23, 59, 59)))
            .sort((a, b) => (a.id && b.id) ? a.id - b.id : 0)
    }));
    const all = [previous, current, future];
    const labels = ["Edelliset päivät", "Tämä päivä", "Seuraavat päivät"]
    // Group same item jobs
    all.forEach(section => {
        section.forEach((ua, i, arr) => {
            const grouped: typeof ua.jobs = [];
            ua.jobs.forEach(uaj => {
                const index = grouped.findIndex(gj => gj.icItem === uaj.icItem);
                if (index > -1) {
                    grouped[index].copies = (grouped[index].copies || 1) + 1;
                } else {
                    grouped.push({ ...uaj, copies: 1 });
                }
            })
            arr[i].jobs = grouped;
        });
    });
    return (
        unassigned?.some(ua => ua?.jobs?.length > 0) ?
            <Stack sx={{ width: "100%" }}>
                <Divider sx={{ fontSize: "0.9rem", fontWeight: "bold" }}>
                    <Tooltip
                        title={"Alihankintana tilatut kuormat valitun suunnittelupäivän viikolta (Ma-Su)"}
                        slotProps={{ tooltip: { sx: { maxWidth: "200px" } } }}
                    >
                        <Box>Alihankinta</Box>
                    </Tooltip>
                </Divider>
                {
                    all.map((group, gi) =>
                        group?.some(g => g?.jobs?.length > 0) &&
                        <Box
                            key={group[0].client.name + "-" + gi}
                            sx={{ width: "100%" }}
                            className={gi === 0 ? "default-red-text" : gi === 2 ? "default-green-text" : undefined}
                        >
                            <Typography sx={{ width: "100%", textAlign: "center", fontSize: "0.8rem" }}>{labels[gi]}:</Typography>
                            {
                                group.map((value) => {
                                    return value.jobs.map((e) =>
                                        <DragBox
                                            key={"dbuc-" + e.interClient + "-" + e.id}
                                            id={String(e.icItem)}
                                            number={
                                                <Stack justifyContent={"center"} alignItems={"center"} sx={{ p: 0.5 }}>
                                                    <img
                                                        src={logoPathsSmall[value.client.name as keyof typeof logoPathsSmall].path}
                                                        style={{
                                                            height: 24,
                                                            ...logoPathsSmall[value.client.name as keyof typeof logoPathsSmall].style || {},
                                                            pointerEvents: "none",
                                                            objectFit: "contain",
                                                        }}
                                                    />
                                                </Stack>
                                            }
                                            tooltip={e.icItemAlias ? `Alkuperäinen nimike: ${e?.item?.name}` : `Nimikkeelle täytyy asettaa alias enen kuin sen voi suunnitella!`}
                                            text={e.icItemAlias ? `${e.icItemAlias.id}:${e.icItemAlias.shortName}` : `${e.icItem}:${e?.item?.shortName}`}
                                            dragStart={(ev: DragEvent) => {
                                                if (props.onDragStartIC)
                                                    props.onDragStartIC(ev, e as PlanningJobInfo);
                                            }}
                                            dragEnd={props.onDragEnd}
                                            disableDrag={!e.icItemAlias}
                                            size={'calc(100% - 10px)'} // Minus margins
                                            color={"inherit"}
                                            backgroundColor={"inherit"}
                                            copies={e.copies}
                                        />
                                    )

                                })
                            }
                        </Box>)
                }
            </Stack>
            : null
    )
}