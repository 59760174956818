import { ArrowForward, Assessment, Close, Delete, Info, List, ListAlt, Send } from '@mui/icons-material'
import {
    Box,
    Button,
    Checkbox, Dialog, DialogActions,
    DialogTitle, FormControl, FormControlLabel,
    IconButton,
    InputLabel,
    Link,
    MenuItem,
    Paper, Select, Table,
    TableBody, TableCell, TableContainer, TableHead,
    TableRow, TableSortLabel, TextField,
    Tooltip,
    Typography
} from '@mui/material'
import { format, parse, subDays } from 'date-fns'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import LogitarApi from '../api/LogitarApi'
import { DateRangeSelect } from '../components/DateSelect'
import { logoPathsSmall } from '../components/PlanningCarRow'
import PopupScreen from '../components/PopupScreen'
import Config from '../config/Config'
import { DatePick } from '../misc/DatePick'
import { Loader, TipButton, getTime, decimalToTime, decimalToTimeV2, timeToDecimal } from '../misc/InternalFeatures'
import ListSelect from '../misc/ListSelect'
import { checkboxWithLabelStyle, contentBoxStyle, exitContainerStyle, exitIconStyle } from '../styles/styles'
import { formatFishStatus, minutesToHoursMinutes, timeStringToHoursMinutes, timeStringToMinutes } from '../components/fish/FishHelpers'
import FishIcon from '../assets/FishIcon'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import FishStatusEdit from '../components/fish/FishStatusEdit'
import { useUserConfig } from '../misc/UserConfig'

/** @typedef {{ jobDateSource: 'billingDate'|'date' }} CheckupConfig */

const fishOptions = Config.getBranch() === 'konnekuljetus' || Config.getBranch() === 'softrain';
const talenomEnabled = Config.moduleEnabled("talenom");

function descendingComparator(a, b, orderBy) {

    /** @type {string} */
    let _a = a[orderBy];
    /** @type {string} */
    let _b = b[orderBy];

    const col = columns.find(e => e.field === orderBy);
    if (col) {
        if (col.type === 'numeric') {

            if (!_a) {
                return 1;
            }

            if (!_b) {
                return -1;
            }

            return Number(_b || '0') - Number(_a || '0');
        }
    }

    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const dialogTextStyle = {
    display: "flex", justifyContent: "space-between", my: 0
}

const filterLabels = {
    showNotSent: { label: "Lähettämättömät:", className: "default-blue", tooltip: "Kuljettaja ei ole lähettänyt" },
    inProgress: { label: "Keskeneräiset:", className: "default-yellow", tooltip: "Kuljettaja lähettänyt keskeneräisenä" },
    accepted: { label: "Hyväksytyt:", className: "default-green", tooltip: "Laskutukseen hyväksytyt" },
    searchWithBilling: { label: "Hae laskutuspäivällä:", className: '', tooltip: "" }
}

const checkersStyle = {
    display: "inherit",
    flexDirection: "row",
    maxWidth: 1000,
    flexWrap: "wrap",
    mb: 2
}

const stateClasses = [
    //blue
    "default-blue",
    //yellow
    "default-yellow",
    //no color
    "",
    //green
    "default-green",
]

const checkFields = {
    "hours": "reqHours",
    "count": "reqCount",
    "tons": "reqTons",
    "m3": "reqM3",
    "kilometres": "reqKm",
    "jobDetails": "reqDetails",
    "waitTime": "reqWaitTime",
    "MWh": "setMWh",
    "talenomVariant": "talenomVariants"
}

/** @param {string} letter */
const getShiftName = (letter) => letter === "A" ? "Aamu" : letter === "I" ? "Ilta" : letter;

//select: (params) => LogitarApi.getCargoTypes(params), base: "cargoType", customLabel: (option) => option.name }
const columns = [
    {
        field: 'date', headerName: 'Ajopvm', longHeader: "Ajopäivämäärä", valueGetter: (row) => {

            const ftime = row.jobSplitID ? row.jobSplitDate : row.date;

            return ftime ? format(new Date(ftime), "dd.MM.yyyy") : ""
        }, fullRow: true, tooltip: (row) => {
            return (<Box>
                {row.loadTime && <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: "space-between", gap: 1 }}>
                    <span>Lastaus:</span><span>{format(new Date(row.loadTime), "dd.MM.yyyy HH:mm:ss")}</span></Box>}
                {row.unloadTime && <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: "space-between", gap: 1 }}>
                    <span>Purku:</span><span>{format(new Date(row.unloadTime), "dd.MM.yyyy HH:mm:ss")}</span></Box>}
            </Box>)
        }
    },
    { field: 'shift', headerName: 'Vuoro', fullRow: true, valueGetter: (row) => getShiftName(row.jobSplitShift ? row.jobSplitShift : row.shift) },
    { field: 'billingDate', headerName: 'Lask.pvm', longHeader: "Laskutuspäivämäärä", timepick: true, valueGetter: (time) => time ? format(new Date(time), "dd.MM.yyyy") : "" },
    { field: 'slot', headerName: 'K.nro', longHeader: "Kuormanumero", fullRow: true, valueGetter: (row) => row.jobSplit ? 1 : (parseInt(row.slot) + 1), type: "numeric" },
    {
        field: 'vehicle', headerName: 'Auto', combo: "licenseNumber",
        valueGetter: (vehicle) => (vehicle),
        select: (params) => LogitarApi.getVehicles(params),
        base: "vehicle",
        customLabel: (option) => `${option.id}:${option.licenseNumber}` || ""
    },
    {
        field: 'user', headerName: 'Kulj.nro', longHeader: "Kuljettaja", select: (params) => LogitarApi.getUsers(params), base: "user", type: "numeric", combo: "jobSplitContinuedBy",
        valueGetter: (users) => {
            const userSplit = String(users).split(":");
            if (!!Number(userSplit[1])) return `${userSplit[0]}→${userSplit[1]}`;
            else return userSplit[0];
        },
    },
    { field: 'usersName', headerName: 'Kulj.nimi', longHeader: 'Kuljettajan nimi' },
    { field: 'jobSplitContinuedBy', headerName: 'Jaetun päättäjä', select: (params) => LogitarApi.getUsers(params), base: "jobSplitContinuedBy", type: "numeric", hidden: true },
    {
        field: 'clientName', headerName: 'Asiakaskoodi',
        fullRow: true,
        valueGetter: (row) => row.icClient ? row.icClient.name : row.clientName,
    },
    {
        field: 'shortName', headerName: 'Lyh.nimike', longHeader: "Nimike",
        fullRow: true,
        valueGetter: (row) => row.icItemAlias ? row.icItemAlias.shortName : row.shortName,
        tooltip: (row) => row.icItemAlias ? `${row.icItemAlias.name} [Alkuperäinen: ${row.name}]` : row.name,
        select: (params) => LogitarApi.getItems(params),
        getDisableSelect: (row) => !!row.icItemAlias,
        base: "item"
    },
    {
        field: 'cargoLabel',
        headerName: 'Tuote',
        fullRow: true,
        valueGetter: (row) => row.icItemAlias ? row.icItemAlias.cargoLabel : row.cargoLabel,
    },
    //{ field: 'itemName', headerName: 'Nimike Numero', width: 220 },
    {
        field: 'basicTypeName',
        headerName: 'Tyyppi',
        fullRow: true,
        valueGetter: (row) => row.icItemAlias ? row.icItemAlias.basicTypeName : row.basicTypeName,
    },
    {
        field: 'talenomVariant',
        headerName: 'Laji',
        base: 'talenomVariant',
        select: (params, item) => { return new Promise((res) => { res({ v: item.talenomVariants.map(e => ({ id: e.product, name: e.name })), status: true }) }); },
        hidden: !talenomEnabled,
    },
    { field: 'fishStatus', headerName: 'Kalojen kunto', check: 'reqFishStatus', fish: true, fishStatus: true },
    { field: 'washLocation', headerName: 'Pesupaikka', check: 'reqWashLocation', fish: true },
    { field: 'washTime', headerName: 'Pesu', longHeader: 'Pesun kesto', check: 'reqWashTime', fish: true, valueGetter: timeStringToHoursMinutes, sumRow: true, timeSum: true, durationPick: true },
    { field: 'loadTime', headerName: 'Lastaus', longHeader: 'Lastauksen kesto', check: 'reqLoadTime', fish: true, valueGetter: timeStringToHoursMinutes, sumRow: true, timeSum: true, durationPick: true },
    { field: 'unloadTime', headerName: 'Purku', longHeader: 'Purun kesto', check: 'reqUnloadTime', fish: true, valueGetter: timeStringToHoursMinutes, sumRow: true, timeSum: true, durationPick: true },
    { field: 'tons', headerName: 'Tonnit', check: "reqTons", type: "numeric", sumRow: true, decimals: 3 },
    { field: 'm3', headerName: 'Kuutiot', check: "reqM3", type: "numeric", sumRow: true, decimals: 3 },
    { field: 'count', headerName: 'Kpl', longHeader: "Kappalemäärä", check: "reqCount", type: "numeric", sumRow: true, decimals: 2 },
    { field: 'kilometres', headerName: 'Km', longHeader: "Kilometrit", check: "reqKm", type: "numeric", sumRow: true, decimals: 2 },
    { field: 'MWh', headerName: 'MWh', check: "setMWh", type: "numeric", sumRow: true, decimals: 2 },
    { field: 'hours', headerName: 'Tunnit', check: "reqHours", valueGetter: getTime, type: "numeric", sumRow: true, decimalSum: true },
    {
        field: 'waitTime', headerName: 'Odotus (min)', check: "reqWaitTime", type: "numeric", sumRow: true, decimals: 0, fullRow: true,
        valueGetter: (row) => <span style={{ display: "inline-flex", alignItems: "center", gap: 6 }}>{row.waitTime}{row.waitTimeReason && <Info sx={{ mb: "2px", fontSize: "1rem" }} />}</span>,
        tooltip: (row) => row.waitTimeReason
    },
    { field: 'waitTimeReason', headerName: 'Odotuksen syy', check: "reqWaitTime", hidden: true },
    { field: 'jobDetails', headerName: 'Lisätieto', check: "reqDetails" },
]

const numericalFields = ["tons", "tonsTare", "tonsGross", "m3", "count", "waitTime", "kilometres", "MWh"]

export default function Checkup() {
    const searchParams = new URL(window.location.href).searchParams;

    /** @type {{ config: CheckupConfig }} */
    const userConfig = useUserConfig("checkup");

    const [timeFrame, setTimeFrame] = useState({
        start: searchParams.get("startDate") ? new Date(searchParams.get("startDate")) : subDays(new Date(Date.now()), 7),
        end: searchParams.get("endDate") ? new Date(searchParams.get("endDate")) : new Date()
    })
    const [jobDateSource, setJobDateSource] = useState(/** @type {'date'|'billingDate'} */(userConfig?.config?.jobDateSource || 'billingDate'));
    const [jobs, setJobs] = useState([])

    const [showType, setShowType] = useState(/** @type {'normal'|'fish'|'all'} */(searchParams.get("itemType") === "fish" ? 'fish' : 'normal'));

    const [postJob, setPostJob] = useState()
    const [modifiedJob, setModifiedJob] = useState({ id: 0 })
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [fetchOptions, setFetchOptions] = useState({
        itemName: searchParams.get("item") ? parseInt(searchParams.get("item")) : false,
        vehicle: searchParams.get("vehicle") ? parseInt(searchParams.get("vehicle")) : false,
        client: searchParams.get("client") ? parseInt(searchParams.get("client")) : false,
        itemNumber: searchParams.get("item") ? parseInt(searchParams.get("item")) : false,
        icContractor: searchParams.get("icContractor") ? searchParams.get("icContractor") : false,
    })
    const [billFilters, setBillFilters] = useState({
        showNotSent: true,
        inProgress: true,
        accepted: searchParams.get("checkState") && Number(searchParams.get("checkState")) === 3 ? true : false,
        //searchWithBilling: false,
    })
    const [loading, setLoading] = useState(true)
    const [loadingOptions, setLoadingOptions] = useState(true);

    const [selectedJobs, setSelectedJobs] = useState([])

    const [clientOptions, setClientOptions] = useState()
    const [carOptions, setCarOptions] = useState()
    const [itemOptions, setItemOptions] = useState()
    const [itemNumberOptions, setItemNumberOptions] = useState()
    const [icOptions, setIcOptions] = useState(/** @type {{ id: string, ic: true, displayName: string }[]} */([]));

    const [count, setCount] = useState(0)

    const [filteredJobs, setFilteredJobs] = useState([])

    const [removing, setRemoving] = useState()

    const [modify, setModify] = useState(false)
    const [showEditFishStatus, setShowEditFishStatus] = useState(false);

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('date');

    const [canSave, setCanSave] = useState(false)

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    useEffect(() => {
        const newOptions = {};

        if (searchParams.get("client")) {
            newOptions.client = parseInt(searchParams.get("client"));
        }
        if (searchParams.get("vehicle")) {
            newOptions.vehicle = parseInt(searchParams.get("vehicle"));
        }
        if (searchParams.get("item")) {
            newOptions.itemName = parseInt(searchParams.get("item"));
            newOptions.itemNumber = parseInt(searchParams.get("item"));
        }
        setFetchOptions(prev => ({ ...prev, ...newOptions }));
    }, [])

    useEffect(() => {
        setLoadingOptions(true);

        LogitarApi.getJobs({ timeFrame: timeFrame, extent: "alljoin", dateCompare: jobDateSource }).then((result) => {

            if (result.status) {

                const nJobs = result.jobs.filter((job) => job.loadTime !== null)
                setJobs(nJobs)
                console.log(result.jobs)
                setSelectedJobs([])

                const clientIDs = new Set();
                const vehicleIDs = new Set();
                const itemNames = new Set();
                const icContractors = new Set();

                const clientOptions = [];
                const carOptions = [];
                const itemOptions = [];
                const itemNumberOptions = [];

                for (const job of nJobs) {

                    if (job.icClient) {
                        /**
                            Evaluation here because if there is going to be more than one of the same client, 
                            then after this it's going to be added to the list, 
                            even when it is not supposed to
                         */
                        if (!clientIDs.has(job.icClient.id)) {
                            clientOptions.push({ id: job.icClient.id, name: job.icClient.name })
                            clientIDs.add(job.icClient.id)
                        }
                    } else if (!clientIDs.has(job.client)) {
                        clientOptions.push({ id: job.client, name: job.clientName })
                        clientIDs.add(job.client);
                    }

                    if (job.icContractor) icContractors.add(job.icContractor);

                    if (!vehicleIDs.has(job.vehicle) && !job.icContractor) // Don't add contractor's vehicles
                        carOptions.push({ id: job.vehicle, name: job.licenseNumber })

                    if (job.icItemAlias) {
                        //Same reason as above
                        if (!itemNames.has(job.icItemAlias.id)) {
                            itemOptions.push({ id: job.icItemAlias.id, name: `${job.icItemAlias.shortName} ${job.icItemAlias.cargoLabel}`, cargoLabel: job.icItemAlias.cargoLabel })
                            itemNumberOptions.push({ id: job.icItemAlias.id, name: job.icItemAlias.itemNumber })
                            itemNames.add(job.icItemAlias.id);
                        }
                    } else if (!itemNames.has(job.item)) {
                        itemOptions.push({ id: job.item, name: `${job.shortName} ${job.cargoLabel}`, cargoLabel: job.cargoLabel })
                        itemNumberOptions.push({ id: job.item, name: job.itemNumber })
                        itemNames.add(job.item);
                    }

                    vehicleIDs.add(job.vehicle);
                }

                setClientOptions(clientOptions.filter(e => e.name !== null).sort((a, b) => a.name.localeCompare(b.name)));
                setCarOptions(carOptions.filter(e => e.id !== null).sort((a, b) => a.id - b.id));
                setItemOptions(itemOptions.filter(e => e.id !== null).sort((a, b) => a.id - b.id));
                setItemNumberOptions(itemNumberOptions.filter(e => e.id !== null).sort((a, b) => a.id - b.id))

                const icClients = Config.getModule("interclient")?.clients || [];
                setIcOptions(icClients.filter(ic => icContractors.has(ic.name)).map(ic => ({ id: ic.name, ic: true, displayName: ic.displayName })));
            }

            setLoading(false)

        })
            .catch((err) => {
                setLoading(false);
                setJobs([])
                setClientOptions([{}])
                setCarOptions([{}])
                setItemOptions([{}])
                setItemNumberOptions([{}]);
                setIcOptions([{}]);
                console.error(err);
            }).finally(() => setLoadingOptions(false))


    }, [timeFrame, jobDateSource, count])

    useEffect(() => {

        if (loading) {
            return
        }

        let tempJobs = [...jobs]

        if (fetchOptions.vehicle) {
            tempJobs = jobs.filter((value) => (value.vehicle === Number(fetchOptions.vehicle) && !value.icContractor)) // Don't show contractor's jobs
        }

        if (fetchOptions.itemName) {
            tempJobs = tempJobs.filter((value) => (value.item === fetchOptions.itemName || (value.icItemAlias && (value.icItemAlias.id === fetchOptions.itemName))))
        }

        if (fetchOptions.client) {
            tempJobs = tempJobs.filter((value) => (value.client === fetchOptions.client || (value.icClient && (value.icClient.id === fetchOptions.client))))
        }

        if (fetchOptions.itemNumber) {
            tempJobs = tempJobs.filter((value) => (value.item === fetchOptions.itemNumber || (value.icItemAlias && (value.icItemAlias.id === fetchOptions.itemNumber))))
        }

        if (fetchOptions.icContractor) {
            tempJobs = tempJobs.filter((value) => (value.icContractor === fetchOptions.icContractor));
        }

        tempJobs = tempJobs.filter((value) => (
            (billFilters["accepted"] && value.checkState === 3)
            || (billFilters["inProgress"] && value.checkState === 1)
            || (billFilters["showNotSent"] && value.checkState === 0)
            || value.checkState === 2)
        )

        if (fishOptions) tempJobs = tempJobs.filter((value) =>
            showType === "normal" ? value.itemType !== "fish" :
                showType === "fish" ? value.itemType === "fish" :
                    showType === "all" ? true : true
        );

        setFilteredJobs(tempJobs)

        setSelectedJobs([])


    }, [fetchOptions, jobs, loading, billFilters, showType])

    const handleOptions = (e) => {

        if (e.target.type === "checkbox") {
            setBillFilters(prev => ({ ...prev, [e.target.name]: !prev[e.target.name] }))
        } else {
            setFetchOptions((prev) => ({ ...prev, [e.target.name]: e.target.value }))
        }
    }


    const handleJobModify = e => {
        // console.log(e)

        let modJob = { ...modifiedJob }
        let pJob = { ...postJob }

        let v = e.target.value

        if (numericalFields.includes(e.target.name)) {
            v = v.replace(",", ".")
        }

        if (columns.some(c => c.field === e.target.name && c.fish)) {
            modJob.jobFish = { ...modJob.jobFish, id: postJob.jobFish.id, [e.target.name]: v };
            pJob.jobFish = { ...pJob.jobFish, id: postJob.jobFish.id, [e.target.name]: v };
        } else {
            modJob[e.target.name] = v
            pJob[e.target.name] = v
        }

        if (e.target.name === "tonsTare" && pJob.tonsGross && !isNaN(v)) {
            const net = Number(pJob.tonsGross) - Number(v);

            modJob["tons"] = String(net.toFixed(3));
            pJob["tons"] = String(net.toFixed(3));

        }
        else if (e.target.name === "tonsGross" && pJob.tonsTare && !isNaN(v)) {
            const net = Number(v) - Number(pJob.tonsTare);
            modJob["tons"] = String(net.toFixed(3));
            pJob["tons"] = String(net.toFixed(3));
        }

        if (e.target.name === "hours") {
            modJob["hours"] = timeToDecimal(v);
            pJob["hours"] = timeToDecimal(v);
        }

        setCanSave(doEnableSaving(pJob))
        setModifiedJob(modJob)
        setPostJob(pJob)
    }

    const doEnableSaving = row => {
        let enableSave = true

        Object.keys(checkFields).forEach((field) => {
            if (Boolean(Number(row[checkFields[field]])) && (row[field] == null || row[field] === "")) {
                enableSave = false
            }
        })

        return enableSave
    }

    const handleListSelect = (name, v) => {

        //handle item and cargo fields, when item changes
        if (name === "item") {

            new Promise((resolve, reject) => {
                let checks = {}

                if (!v) {
                    Object.values(checkFields).forEach((field) => { checks[field] = 0 })
                    resolve({ fields: { [name]: null, cargoType: null, cargoLabel: null }, checks: checks })

                } else {
                    LogitarApi.getItems({ id: v.id, extent: "all" }).then((result) => {

                        Object.values(checkFields).forEach((field) => {
                            checks[field] = result.items[0][field]
                        })

                        resolve({
                            fields: { [name]: v.id, cargoType: result.items[0].cargoType, cargoLabel: result.items[0].cargoLabel },
                            checks: checks
                        })
                        console.log("setting jobs")
                    })
                }
            })
                .then((result) => {
                    let resetEditFields = { modify: {}, show: {} }
                    console.log(result, Object.values(checkFields))

                    Object.keys(checkFields).forEach((field) => {

                        if (Boolean(Number(result.checks[checkFields[field]]))) {
                            return
                        }

                        if (field === "tons") {
                            resetEditFields.show["tonsTare"] = ""
                            resetEditFields.modify["tonsTare"] = null
                            resetEditFields.show["tonsGross"] = ""
                            resetEditFields.modify["tonsGross"] = null
                        }

                        resetEditFields.show[field] = "";
                        resetEditFields.modify[field] = null
                    })

                    console.log(result, resetEditFields)
                    let pJob = { ...postJob, ...result.fields, ...result.checks, ...resetEditFields.show }

                    setCanSave(doEnableSaving(pJob))
                    setModifiedJob(prev => ({ ...prev, ...result.fields, ...resetEditFields.modify }))
                    setPostJob(prev => ({ ...prev, ...result.fields, ...result.checks, ...resetEditFields.show }))
                })

            return
        }

        // setCanSave(false)
        setModifiedJob(prev => ({ ...prev, [name]: v ? v.id : null }))
        setPostJob(prev => ({ ...prev, [name]: v ? v.id : null }))
    }

    //Accept all checkState = 2 and selected jobs to be sent for billing
    const acceptJobs = () => {
        //Filter any not ready jobs 
        let readyJobs = filteredJobs.filter((value) => value.checkState === 2 && selectedJobs.includes(value.id))
        //Remove useless data
        readyJobs.forEach((value, i, arr) => {
            arr[i] = {
                id: value.id,
                checkState: 3,
                // Set icOrder, interClient fields if this this client has ordered this job
                icOrder: value.interClient === Config.getBranch() ? true : undefined,
                interClient: value.interClient === Config.getBranch() ? value.icContractor : undefined
            }
        })

        if (readyJobs.length < 1) return

        LogitarApi.setJobs(readyJobs).then((result) => {

            if (result.status) {
                console.log(result)
                setCount(prev => prev + 1)
                enqueueSnackbar("Lähetys onnistui")
            }

        })
            .catch((err) => { console.error(err) })
    }

    const openModify = (row) => {
        console.log(row)
        setCanSave(doEnableSaving(row))
        setModifiedJob({ id: row.id })
        setPostJob(row)
        setModify(true);
    }

    const selectingJobs = (id = null) => {
        if (!id) {

            if (selectedJobs.length > 0) {
                setSelectedJobs([])

            } else {
                setSelectedJobs(filteredJobs.map((job, key) => (job.checkState === 2 ? job.id : null)))

            }
            return
        }

        const exists = selectedJobs.includes(id)

        if (exists) {
            setSelectedJobs(prev => prev.filter((jobid) => jobid !== id))
        } else {
            setSelectedJobs(prev => ([...prev, id]))
        }
    }

    //Check all user filled info fields for setting checkState
    const checkJob = (row) => {
        let checkState = row.checkState
        if (checkState === 3 || checkState === 0) {

        } else {
            checkState = 2
            Object.keys(checkFields).forEach((field) => {

                if (Boolean(Number(row[checkFields[field]])) && !row[field]) {
                    checkState = 1
                }
            })
        }

        console.log({
            ...modifiedJob,
            checkState: checkState,
        })
        saveJob({
            ...modifiedJob,
            checkState: checkState,
            // Set icOrder if contractor is set
            icOrder: row.icContractor ? row.icContractor : undefined,
            interClient: row.icContractor ? row.icContractor : undefined,
            //Force update otherwise commit would be required
            ">forceUpdate": true
        })
    }

    const saveJob = (job) => {

        let sentItem = {}
        Object.keys(job).forEach((field) => {
            if (job[field] === "") {
                sentItem[field] = null
            } else {
                sentItem[field] = job[field]
            }

        })

        console.log(sentItem)

        LogitarApi.setJobs([sentItem]).then((result) => {
            console.log(result)
            setModify(false)
            setModifiedJob({ id: 0 })
            setCount(prev => prev + 1)
            enqueueSnackbar("Kuorma tallennettu")
        })
            .catch((err) => {
                enqueueSnackbar("Kuorman tallentaminen epäonnistui", { variant: "error" });
                console.error(err);
            })
    }

    const removeJob = (job) => {
        console.log("Removing " + job.id, postJob)
        console.log("Removing from " + (postJob.interClient === Config.getBranch() ? "alih" : "own") + " system");
        const icFields = postJob.interClient === Config.getBranch() ? {
            "icOrder": postJob.icContractor,
            "interClient": postJob.icContractor
        } : {}
        //Call this from confirmation dialog, here call api for remove, then update page
        LogitarApi.setJobs([{ id: job.id, ">deleted": true, ">commit": true, ...icFields }])
            .then((result) => {
                enqueueSnackbar("Kuorma poistettu");
                setCount(prev => prev + 1);
                setRemoving(undefined);
                setModify(false); setModifiedJob({ id: 0 });
            })
            .catch((err) => {
                enqueueSnackbar("Kuorman poisto epäonnistui", { variant: "error" });
                console.error(err)
            });
    }

    const totalsColSpan = () => {
        let span = 12;
        if (talenomEnabled) span += 1;
        if (showType !== 'normal') span += 3;
        return span;
    }

    if (loading) {
        return (
            <Loader />
        )
    }

    return (
        <Box sx={{
            ...contentBoxStyle, display: "flex", flexDirection: "column",

            '& .MuiTypography-root': {
                mb: 2
            }, '& .MuiFormControl-root ': {
                mr: 1,
                mb: 2,
                minWidth: 100,

                height: 35
            }, '& .MuiCheckbox-root': {
                p: 0, ml: "4px"
            }, '& .MuiOutlinedInput-root': {
                mr: 1,
                mb: 2,
                minWidth: 150,

                height: 35,
            }
        }}>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", px: 1 }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "", flexWrap: "wrap" }}>

                        <DateRangeSelect
                            value={[timeFrame.start, timeFrame.end]}
                            onChange={(v) => {
                                if (v[0] && v[1])
                                    setTimeFrame({ start: v[0], end: v[1] })
                            }}
                            labels={jobDateSource === 'date' ? { start: "Ajopvm alku", end: "Ajopvm loppu" } : { start: "Laskutuspvm alku", end: "Laskutuspvm loppu" }}
                            isLoading={loadingOptions}
                            inputStyle={{ width: "8rem" }}
                            sx={{
                                '& .MuiFormControl-root ': {
                                    mr: 0,
                                    minWidth: "unset",
                                }, '& .MuiOutlinedInput-root': {
                                    mr: 0,
                                    minWidth: "unset"
                                },
                                mr: 2
                            }}
                        />
                        <FormControl disabled={loadingOptions}>
                            <InputLabel shrink={true}>Päivämäärähaku</InputLabel>
                            <Select
                                label="Päivämäärähaku"
                                onChange={(e) => {
                                    setJobDateSource(e.target.value);
                                    userConfig.config = { ...userConfig.config, jobDateSource: e.target.value };
                                }}
                                value={jobDateSource}
                                notched
                                size='small'
                            >
                                <MenuItem value="billingDate">Laskutuspvm</MenuItem>
                                <MenuItem value="date">Ajopvm</MenuItem>
                            </Select>
                        </FormControl>

                        {fishOptions &&
                            <FormControl disabled={loadingOptions}>
                                <InputLabel shrink={true}>Kuorman tyyppi</InputLabel>
                                <Select notched size='small' label="Kuorman tyyppi" value={showType} onChange={(e) => setShowType(e.target.value)}>
                                    <MenuItem value="normal">Normaali</MenuItem>
                                    <MenuItem value="fish">Kala</MenuItem>
                                    <MenuItem value="all">Kaikki</MenuItem>
                                </Select>
                            </FormControl>}

                        {[
                            { field: "client", label: "Asiakaskoodi", options: clientOptions, width: 230 },
                            { field: "vehicle", label: "Auto", options: carOptions, width: 180 },
                            { field: "itemName", label: "Lyhennetty Nimike", options: itemOptions, width: 400 },
                            { field: "itemNumber", label: "Nimike Numero", options: itemNumberOptions, width: 300 }
                        ].map((select, key) => (
                            select.field === "vehicle"
                            ?
                            <ListSelect
                                key={key}
                                sx={{ mr: 2, width: select.width }}
                                value={fetchOptions[select.field] ? fetchOptions[select.field] : fetchOptions.icContractor}
                                dataHandle={(e, v) => {
                                    if (v && v.ic) {
                                        handleOptions({ ...e, target: { name: "icContractor", value: v.id } })
                                        handleOptions({ ...e, target: { name: select.field, value: false } })
                                    } else {
                                        handleOptions({ ...e, target: { name: select.field, value: v ? v.id : false } })
                                        handleOptions({ ...e, target: { name: "icContractor", value: false } })
                                    }
                                }}
                                name={select.field}
                                label={select.label + (Config.getModule("interclient")?.enabled ? " / Alihankkija" : "")}
                                externalOptions={[...select.options, ...icOptions]}
                                initialValue={fetchOptions[select.field] ? fetchOptions[select.field] : fetchOptions.icContractor ? fetchOptions.icContractor : undefined}
                                loading={loadingOptions}
                                customLabel={(option) => option.ic ? `${option.displayName}` : `${option.id}:${option.name}`}
                            />
                            :
                            <ListSelect
                                key={key}
                                sx={{ mr: 2, width: select.width }}
                                value={fetchOptions[select.field]}
                                dataHandle={(e, v) => handleOptions({ ...e, target: { name: select.field, value: v ? v.id : false } })}
                                name={select.field}
                                label={select.label}
                                externalOptions={select.options}
                                initialValue={fetchOptions[select.field] ? fetchOptions[select.field] : undefined}
                                loading={loadingOptions}
                            />
                        ))
                        }

                    </Box>


                    <Box sx={{
                        display: "flex", justifyContent: "space-between",
                        '& .MuiFormControlLabel-root': {
                            alignItems: "flex-start",
                            ml: "1px",
                            maxHeight: 40,
                            minWidth: "150px",
                            justifyContent: "space-between"

                        }
                    }}>
                        <FormControlLabel
                            sx={{ flexDirection: "row", pl: 0.8, py: 1 }}
                            control={<Checkbox
                                name={"selectAll"}
                                checked={selectedJobs.length === filteredJobs.length && selectedJobs.length > 0}
                                onClick={() => selectingJobs()}
                            />}
                            label={"Valitse kaikki laskutukseen"}
                        />
                        <Box sx={{ ...checkersStyle, mb: 0 }}>
                            {Object.keys(billFilters).map((filter, key) => (
                                <Tooltip key={key} title={filterLabels[filter].tooltip}>
                                    <FormControlLabel
                                        sx={{ ...checkboxWithLabelStyle }}
                                        className={filterLabels[filter].className}
                                        control={<Checkbox
                                            name={filter}

                                            onChange={handleOptions}
                                            value={billFilters[filter]}
                                            checked={billFilters[filter]}
                                            sx={{ color: "text.primary", "&.Mui-checked": { color: 'text.primary' } }}
                                        />}
                                        label={filterLabels[filter].label}
                                    />
                                </Tooltip>
                            ))}
                        </Box>
                    </Box>
                </Box>

                <Box>
                    <TipButton title="Hyväksy laskutukseen" variant="contained" icon={Send} onClick={acceptJobs} />
                </Box>
            </Box>

            {filteredJobs.length > 0 ?
                <TableContainer component={Paper} sx={{ maxHeight: "75vh", overflowX: "auto" }}>
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: "bold", py: "4px", px: 1 }}>Valinta</TableCell>
                                {showType !== 'normal' && <TableCell sx={{ px: 1 }}>Tyyppi</TableCell>}
                                {columns.filter(col => !col.hidden).filter(col => showType === "normal" ? !col.fish : true).map((header, key) => (
                                    <TableCell sx={{ fontWeight: "bold", p: "4px", width: header.width || "auto" }} key={key}
                                        sortDirection={orderBy === header.field ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === header.field}
                                            direction={orderBy === header.field ? order : 'asc'}
                                            onClick={createSortHandler(header.field)}
                                            sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}
                                        >
                                            {header.headerName}
                                        </TableSortLabel>
                                    </TableCell>

                                ))}

                            </TableRow>
                        </TableHead>

                        <TableBody>

                            {filteredJobs.sort(getComparator(order, orderBy)).map((row, i) => {
                                const selectedToDialog = modifiedJob.id === row.id
                                const isOrderer = row.interClient === Config.getBranch();
                                return (
                                    <TableRow className={selectedToDialog ? "default-grey" : stateClasses[row["checkState"]]} sx={{ maxHeight: 40 }} key={i}>
                                        <TableCell sx={{ position: 'relative', px: 1, py: 0, width: 20, textAlign: "center", "& .MuiCheckbox-root": { ml: 0 } }}>
                                            {
                                                row.interClient &&
                                                <Box sx={{ position: 'absolute', left: 1, top: 0 }}>
                                                    <Tooltip title={isOrderer ? `${row.icContractor} suorittaja` : `${row.interClient} tilaaja`}>
                                                        {
                                                            isOrderer ?
                                                                <span>
                                                                    <img src={logoPathsSmall[row.interClient].path} alt={row.interClient} style={{ height: 10, marginRight: 1 }} />
                                                                    <ArrowForward sx={{ height: 10, width: 10 }} />
                                                                    {row.icContractor &&
                                                                        <img src={logoPathsSmall[row.icContractor].path} alt={row.icContractor} style={{ height: 10, marginRight: 1 }} />
                                                                    }
                                                                </span> :
                                                                <span>
                                                                    <img src={logoPathsSmall[row.interClient].path} alt={row.interClient} style={{ height: 10, marginRight: 1 }} />
                                                                    <ArrowForward sx={{ height: 10, width: 10 }} />
                                                                        <img src={logoPathsSmall[Config.getBranch()].path} alt={Config.getBranch()} style={{ height: 10, marginRight: 1 }} />
                                                                </span>
                                                        }
                                                    </Tooltip>
                                                </Box>
                                            }
                                            {row.checkState === 2 &&
                                                <Checkbox onClick={() => selectingJobs(row.id)} checked={selectedJobs.includes(row.id)}></Checkbox>
                                            }
                                        </TableCell>
                                        {showType !== 'normal' && <TableCell>{row.itemType === "fish" && <FishIcon />}</TableCell>}

                                        {columns.filter(col => !col.hidden).filter(col => showType === "normal" ? !col.fish : true).map((data, key) => {
                                            // Handle fish status array
                                            if (data.fishStatus) {
                                                const statusLength = row?.jobFish?.fishStatus ? row.jobFish.fishStatus.length : 0;
                                                return (
                                                    <TableCell
                                                        onClick={() => {
                                                            openModify(row);
                                                        }}
                                                        sx={{ overflowWrap: "normal", p: 1, cursor: "pointer" }}
                                                        key={key}
                                                    >
                                                        {statusLength > 0 &&
                                                            <Tooltip title={formatFishStatus(row?.jobFish?.fishStatus)}>
                                                                <span style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}>
                                                                    <List sx={{ mb: 0.2 }} />{statusLength < 2 ? `${statusLength} kirjaus` : `${statusLength} kirjausta`}
                                                                </span>
                                                            </Tooltip>}
                                                    </TableCell>
                                                )
                                            }

                                            let params = data.combo ? `${row[data.field]}:${row[data.combo]}` : row[data.field]
                                            //Give a full row to valueGetter if it is set
                                            if (data.fullRow !== undefined) {
                                                params = row;
                                            }

                                            // No support for fullRow or combo for fish fields
                                            if (data.fish) params = row?.jobFish[data.field];

                                            const fieldData = data.valueGetter ? data.valueGetter(params) : null


                                            if (data.tooltip) {
                                                return (
                                                    <Tooltip key={key} title={data.tooltip(row)}>
                                                        <TableCell
                                                            onClick={() => {
                                                                openModify(row);
                                                            }}
                                                            sx={{ overflowWrap: "normal", p: 1, cursor: "pointer" }}
                                                        >
                                                            {fieldData || (data.fish ? row?.jobFish[data.field] : row[data.field])}
                                                        </TableCell>
                                                    </Tooltip>
                                                )
                                            }
                                            return (
                                                <TableCell
                                                    onClick={() => {
                                                        openModify(row);
                                                    }}
                                                    sx={{ overflowWrap: "normal", p: 1, cursor: "pointer" }}
                                                    key={key}
                                                >
                                                    {fieldData || (data.fish ? row?.jobFish[data.field] : row[data.field])}
                                                </TableCell>
                                            )
                                        })}

                                    </TableRow>
                                )
                            })

                            }
                            <TableRow>
                                <TableCell sx={{ fontWeight: "bold" }} colSpan={totalsColSpan()} align='right'>Yhteensä:</TableCell>
                                {columns.filter(col => showType === "normal" ? !col.fish : true).filter(col => col.sumRow).map((col, key) => (
                                    <TableCell key={key} sx={{ p: 1, fontWeight: "bold" }}>
                                        {col.timeSum ?
                                            minutesToHoursMinutes(filteredJobs.reduce((p, c) => p + timeStringToMinutes(col.fish ? c?.jobFish[col.field] : c[col.field]), 0)) :
                                            (
                                                col.decimalSum ?
                                                    decimalToTimeV2(filteredJobs.reduce((p, c) => p + Number(c[col.field]), 0).toFixed(col.decimals != null ? col.decimals : 2)) :
                                                    filteredJobs.reduce((p, c) => p + Number(c[col.field]), 0).toFixed(col.decimals != null ? col.decimals : 2)
                                            )
                                        }
                                    </TableCell>
                                ))}
                                <TableCell />
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ fontWeight: "bold" }} colSpan={totalsColSpan()} align='right'>Keskiarvo:</TableCell>
                                {columns.filter(col => showType === "normal" ? !col.fish : true).filter(col => col.sumRow).map((col, key) => {
                                    const count = filteredJobs.filter(j => col.fish ? j?.jobFish[col.field] : j[col.field]).length;
                                    return (
                                        <TableCell key={key} sx={{ p: 1, fontWeight: "bold" }}>
                                            {col.timeSum ?
                                                (count > 0 ? (minutesToHoursMinutes(filteredJobs.reduce((p, c) => p + timeStringToMinutes(col.fish ? c?.jobFish[col.field] : c[col.field]), 0) / count)) : 0) :
                                                (
                                                    col.decimalSum ?
                                                        (count > 0 ? (decimalToTimeV2(filteredJobs.reduce((p, c) => p + Number(c[col.field]), 0) / count)) : 0) :
                                                        (count > 0 ? (filteredJobs.reduce((p, c) => p + Number(c[col.field]), 0) / count) : 0).toFixed(col.decimals != null ? col.decimals : 2)
                                                )
                                            }
                                        </TableCell>
                                    )
                                })}
                                <TableCell />
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                : <Typography sx={{ p: 2 }}>Ei toteutuksia hakuvaihtoehdoilla</Typography>}

            {modify &&
                <PopupScreen
                    title="Ajetun muokkaus"
                    draggable
                    onClose={() => { setModify(false); setModifiedJob({ id: 0 }) }}
                    onDelete={() => setRemoving(postJob.id)}
                    onSave={() => { checkJob(postJob) }}
                    saveProps={{ disabled: !canSave }}
                    staticButtons
                >
                    <Box sx={{
                        width: "1000px", "& .MuiFormControl-root": { mr: 0, mb: 0, width: "100%", height: 28 },
                        "& .MuiOutlinedInput-root": { width: "100%", paddingTop: 0 },
                        "& .MuiOutlinedInput-input": { height: 20 },
                        "& .MuiInputBase-root": { height: 28, m: 0 },
                        "& .MuiInputBase-input": { height: 15, paddingTop: 0, paddingBottom: 0, },

                        pb: 0, pt: 0, "& .MuiBox-root": { height: 30 }
                    }}>
                        {
                            !postJob.interClient &&
                            <Tooltip title="Avaa kuormaraporteissa">
                                <IconButton
                                    sx={{
                                        position: "absolute",
                                        right: 20,
                                        top: 40,
                                        p: 0
                                    }}
                                    component={Link}
                                    href={"/jobreports?vehicle=" + postJob.vehicle +
                                        "&item=" + postJob.item +
                                        "&start=" + format(new Date(postJob.date), "yyyy-MM-dd") +
                                        "&end=" + format(new Date(postJob.date), "yyyy-MM-dd")}
                                    target="_blank"
                                >
                                    <Assessment />
                                </IconButton>
                            </Tooltip>
                        }

                        {postJob &&
                            columns.map((data, key) => {
                                if (data.fish && postJob.itemType !== "fish") return;

                                // Don't display jobSplitContinuedBy if there is no jobSplit for the current job
                                if (!postJob.jobSplit && data.field === "jobSplitContinuedBy") return;

                                // Cannot edit something that is ordered by this client
                                if (postJob.interClient && postJob.interClient === Config.getBranch()) {
                                    if (data.field === 'vehicle' || data.field === 'item' || data.field === 'shortName' || data.field === 'user') {
                                        let params = data.combo ? `${postJob[data.field]}:${postJob[data.combo]}` : postJob[data.field]
                                        if (data.fullRow === true) {
                                            params = postJob;
                                        }
                                        const getValue = data.valueGetter ? data.valueGetter(params) : postJob[data.field]

                                        return <Box key={key} sx={{ ...dialogTextStyle, mt: 0.5 }}>
                                            <span style={{ flex: 1 }}>{data.longHeader ? data.longHeader : data.headerName}:</span>
                                            <span style={{ marginLeft: 20, textAlign: "left", flex: 3, overflowWrap: "anywhere" }}>{getValue}</span>
                                        </Box>
                                    }
                                }

                                if (data.check) {

                                    let disabled = postJob[data.check] === 0
                                    if (data.fish) disabled = postJob?.itemFish[data.check] === 0;

                                    if (data.fishStatus) {
                                        return (
                                            <Box key={key} sx={{ ...dialogTextStyle }} style={{ height: "33px" }}>
                                                <span style={{ color: disabled ? "palette.action.disabled" : undefined, flex: 1 }}>{data.longHeader ? data.longHeader : data.headerName}:</span>
                                                {!disabled &&
                                                    <Box sx={{ flex: 3 }}>
                                                        <Button
                                                            variant="outlined"
                                                            startIcon={<ListAlt />}
                                                            onClick={() => setShowEditFishStatus(true)}
                                                            size='small'
                                                            fullWidth
                                                        >
                                                            Kalojen kunnon muokkaus
                                                        </Button>
                                                        <FishStatusEdit
                                                            fishStatus={postJob?.jobFish?.fishStatus}
                                                            open={showEditFishStatus}
                                                            onClose={() => setShowEditFishStatus(false)}
                                                            onConfirm={(ns) => handleJobModify({ target: { name: data.field, value: ns } })}
                                                        />
                                                    </Box>
                                                }
                                            </Box>
                                        );
                                    }

                                    if (data.durationPick) {
                                        return (
                                            <Box key={key} sx={{ ...dialogTextStyle }}>
                                                <span style={{ color: disabled ? "palette.action.disabled" : undefined, flex: 1 }}>{data.longHeader ? data.longHeader : data.headerName}:</span>
                                                {!disabled &&
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <TimePicker
                                                            onChange={(v) => {
                                                                if (!v || !v.getTime()) {
                                                                    handleJobModify({ target: { name: data.field, value: null } });
                                                                } else {
                                                                    handleJobModify({ target: { name: data.field, value: format(v, "HH:mm:ss") } });
                                                                }
                                                            }}
                                                            views={["hours", "minutes"]}
                                                            format="HH:mm"
                                                            ampm={false}
                                                            minutesStep={15}
                                                            skipDisabled
                                                            maxTime={new Date(0, 0, 0, 8, 59)}
                                                            value={data.fish ?
                                                                parse(postJob?.jobFish[data.field], "HH:mm:ss", new Date()) :
                                                                parse(postJob[data.field], "HH:mm:ss", new Date())}
                                                            sx={{ flex: 3 }}
                                                            slotProps={{ textField: { error: false } }}
                                                        />
                                                    </LocalizationProvider>
                                                }
                                            </Box>
                                        )
                                    }

                                    if (data.field === "tons") {
                                        if (postJob[data.check] === 2) {
                                            return (
                                                <Box key={key} sx={{ ...dialogTextStyle }}>
                                                    <span style={{ flex: 1 }}>{data.longHeader ? data.longHeader : data.headerName} (netto):</span>

                                                    <TextField
                                                        onChange={handleJobModify}
                                                        name={data.field}
                                                        sx={{ flex: 3 }}
                                                        value={postJob[data.field]}
                                                    />

                                                </Box>
                                            )
                                        }

                                        return (
                                            <Box key={key} sx={{ ...dialogTextStyle }}>
                                                <span style={{ color: disabled ? "palette.action.disabled" : undefined, flex: 1 }}>{data.longHeader ? data.longHeader : data.headerName} (netto/taara/brutto):</span>
                                                {!disabled &&
                                                    <Box sx={{ display: "inline-flex", flex: 3, flexDirection: "row", gap: "0.25rem", width: "100%" }}>
                                                        <TextField
                                                            onChange={handleJobModify}
                                                            name={data.field}
                                                            disabled={disabled}

                                                            value={postJob[data.field]}
                                                            sx={{ "& .MuiOutlinedInput-root": { minWidth: "unset" } }}
                                                        />
                                                        <TextField
                                                            onChange={handleJobModify}
                                                            name={"tonsTare"}
                                                            disabled={disabled}

                                                            value={postJob["tonsTare"]}
                                                            sx={{ "& .MuiOutlinedInput-root": { minWidth: "unset" } }}
                                                        />
                                                        <TextField
                                                            onChange={handleJobModify}
                                                            name={"tonsGross"}
                                                            disabled={disabled}

                                                            value={postJob["tonsGross"]}
                                                            sx={{ "& .MuiOutlinedInput-root": { minWidth: "unset" } }}
                                                        />
                                                    </Box>
                                                }
                                            </Box>
                                        )
                                    }

                                    if (data.field === "hours") {
                                        return (
                                            <Box key={key} sx={{ ...dialogTextStyle }}>
                                                <span style={{ color: disabled ? "palette.action.disabled" : undefined, flex: 1 }}>{data.longHeader ? data.longHeader : data.headerName}:</span>
                                                {!disabled &&
                                                    <Box sx={{ display: "inline-flex", flex: 3, width: "100%" }}>
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <TimePicker
                                                                onChange={(v) => {
                                                                    if (!v || !v.getTime()) {
                                                                        handleJobModify({ target: { name: data.field, value: null } });
                                                                    } else {
                                                                        handleJobModify({ target: { name: data.field, value: format(v, "HH:mm:ss") } });
                                                                    }
                                                                }}
                                                                views={["hours", "minutes"]}
                                                                format="HH:mm"
                                                                ampm={false}
                                                                minutesStep={15}
                                                                skipDisabled
                                                                maxTime={new Date(0, 0, 0, 23, 59)}
                                                                value={
                                                                    parse(decimalToTime(postJob[data.field]), "HH:mm:ss", new Date()).getTime() ?
                                                                        parse(decimalToTime((postJob[data.field])), "HH:mm:ss", new Date()) :
                                                                        null
                                                                }
                                                            />
                                                        </LocalizationProvider>

                                                        {/* Tyhjää muotoiluun*/}
                                                        <Box sx={{ height: "100%", minWidth: "501px", }}></Box>
                                                    </Box>
                                                }

                                            </Box>
                                        )
                                    }

                                    if (data.field === "jobDetails") {
                                        return (
                                            <Box key={key} sx={{ ...dialogTextStyle }}>
                                                <span style={{ color: disabled ? "palette.action.disabled" : undefined, flex: 1 }}>{data.longHeader ? data.longHeader : data.headerName}:</span>
                                                {!disabled &&
                                                    <TextField
                                                        onChange={handleJobModify}
                                                        name={data.field}
                                                        disabled={disabled}
                                                        sx={{ flex: 3 }}
                                                        value={data.fish ? postJob?.jobFish[data.field] : postJob[data.field]}
                                                    />
                                                }
                                            </Box>
                                        )
                                    }

                                    return (
                                        <Box key={key} sx={{ ...dialogTextStyle }}>
                                            <span style={{ color: disabled ? "palette.action.disabled" : undefined, flex: 1 }}>{data.longHeader ? data.longHeader : data.headerName}:</span>
                                            {!disabled &&
                                                <Box sx={{ display: "inline-flex", flex: 3, width: "100%" }}>
                                                    <TextField
                                                        onChange={handleJobModify}
                                                        name={data.field}
                                                        disabled={disabled}
                                                        value={data.fish ? postJob?.jobFish[data.field] : postJob[data.field]}
                                                    />

                                                    {/* Tyhjää muotoiluun*/}
                                                    <Box sx={{ height: "100%", minWidth: "501px", }}></Box>
                                                </Box>
                                            }
                                        </Box>
                                    )
                                }

                                if (data.timepick) {

                                    return (

                                        <Box key={key} sx={{ ...dialogTextStyle }}>
                                            <span style={{ flex: 1 }}>{data.longHeader ? data.longHeader : data.headerName}:</span>

                                            <DatePick dateSet={(v) => {
                                                if (!v || isNaN(v.getTime())) {
                                                    return
                                                }

                                                setModifiedJob(prev => ({ ...prev, [data.field]: format(new Date(v), "yyyy-MM-dd") }))
                                                setPostJob(prev => ({ ...prev, [data.field]: format(new Date(v), "yyyy-MM-dd") }))

                                            }}
                                                date={!postJob[data.field] ? new Date() : new Date(postJob[data.field])}
                                                sx={{ flex: 3 }} height={28} />

                                        </Box>
                                    )
                                }


                                if (data.select && postJob.checkState >= 2 && !(data.getDisableSelect ? data.getDisableSelect(postJob) : false)) {

                                    const itemSwitch = data.base === "item"

                                    if (data.base === "talenomVariant" && (!Array.isArray(postJob.talenomVariants) || postJob.talenomVariants.length === 0)) {
                                        // Single product number in use, don't show the select
                                        return null;
                                    }

                                    return (
                                        <Box key={key} sx={{ ...dialogTextStyle }}>
                                            <Typography sx={{ color: "text.primary", flex: 1 }}>{data.longHeader ? data.longHeader : data.headerName}:</Typography>
                                            <ListSelect
                                                sx={{ flex: 3 }}

                                                getCall={(e) => data.select(e, postJob)}
                                                callParams={itemSwitch ? { extent: "list", client: postJob["client"] } : { extent: "list" }}
                                                dataHandle={(event, val) => handleListSelect(data.base, val)}
                                                customLabel={data.customLabel || null}
                                                value={postJob[data.base]}
                                                disableClearable
                                            />
                                        </Box>
                                    )

                                }

                                let params = data.combo ? `${postJob[data.field]}:${postJob[data.combo]}` : postJob[data.field]
                                if (data.fullRow === true) {
                                    params = postJob;
                                }
                                const getValue = data.valueGetter ? data.valueGetter(params) : postJob[data.field]

                                if (data.field === "user" && postJob.checkState < 2) {
                                    const params2 = postJob["usersName"]
                                    const getValue2 = data.valueGetter ? data.valueGetter(params2) : postJob["usersName"]
                                    return (
                                        <Box key={key} sx={{ ...dialogTextStyle, mt: 0.5 }}>
                                            <span style={{ flex: 1 }}>{data.longHeader ? data.longHeader : data.headerName}:</span>
                                            <Box sx={{ display: "inline-flex", flex: 3, width: "100%" }}>
                                                <span style={{ marginLeft: 14, textAlign: "left", overflowWrap: "anywhere" }}>{getValue}</span>
                                                <span style={{ marginLeft: 20, textAlign: "left", overflowWrap: "anywhere" }}>{getValue2}</span>
                                            </Box>
                                        </Box>
                                    )
                                }

                                if (data.field != "usersName") {
                                    return (
                                        <Box key={key} sx={{ ...dialogTextStyle, mt: 0.5 }}>
                                            <span style={{ flex: 1 }}>{data.longHeader ? data.longHeader : data.headerName}:</span>
                                            <span style={{ marginLeft: 20, textAlign: "left", flex: 3, overflowWrap: "anywhere" }}>{getValue}</span>
                                        </Box>
                                    )
                                }
                            })
                        }
                    </Box>
                </PopupScreen>
            }

            <Dialog open={removing !== undefined} onClose={() => setRemoving()}>

                <Box sx={exitContainerStyle}>
                    <DialogTitle sx={{ pl: 2 }}>Toteutuksen poisto</DialogTitle>
                    <Close sx={{ ...exitIconStyle }} variant="contained"
                        onClick={() => { setRemoving() }}>
                        Poistu
                    </Close>
                </Box>

                <DialogTitle>
                    {"Haluatko varmasti poistaa valitun kuorman?"}
                </DialogTitle>

                <DialogActions>

                    <TipButton onClick={() => removeJob(modifiedJob)} title="Poista" variant="contained" icon={Delete} />
                </DialogActions>

            </Dialog>

        </Box>
    )
}