import { Card, Divider, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { format, setDate } from "date-fns";
import { useEffect, useRef, useState } from "react";
import LogitarApi from "../api/LogitarApi";
import { enqueueSnackbar } from "notistack";
import { logitarThemeMode } from "../config/Themes";

/**
 * @enum {number}
 */
export const FuelType = {
    UNKNOWN:    0,
    diesel:     1,
    gas:        2,
    electric:   3,
    hydrogen:   4,
    biodiesel:  5
}

/**
 * @enum {number}
 */
export const FuelVendor = {
    UNKNOWN:    0,
    neste:      1,
    st1:        2,
    teboil:     3,
    gasum:      4
}

/**
 * @type {{ label: string, value: FuelVendor, fuelTypes: FuelType[] }[]}
 */
const vendorList = [
    { label: "Neste", value: FuelVendor.neste, fuelTypes: [FuelType.diesel, FuelType.electric, FuelType.biodiesel, FuelType.hydrogen] },
    { label: "St1", value: FuelVendor.st1, fuelTypes: [FuelType.diesel, FuelType.electric, FuelType.biodiesel, FuelType.gas, FuelType.hydrogen] },
    { label: "Teboil", value: FuelVendor.teboil, fuelTypes: [FuelType.diesel, FuelType.electric, FuelType.biodiesel, FuelType.hydrogen] },
    { label: "Gasum", value: FuelVendor.gasum, fuelTypes: [FuelType.gas] }
]

export const fuelTypeList = [
    { label: "Diesel", value: FuelType.diesel, unit: "L" },
    { label: "Kaasu", value: FuelType.gas, unit: "kg" },
    { label: "Sähkö", value: FuelType.electric, unit: "kWh" },
    { label: "Vety", value: FuelType.hydrogen, unit: "kg" },
    { label: "Biodiesel", value: FuelType.biodiesel, unit: "L" },
]

const fuelPriceThemes = [
    "green",
    "yellow",
    "orange",
    "red"
];

const date = new Date();

/**
 * @typedef {{
 *  editable?: boolean,
 *  fuelType?: FuelType,
 *  variant?: "full"|"small"|"colors",
 *  sx?: object,
 *  horizontal?: boolean,
 * }} FuelPriceCardProps
 */


/**
 * 
 * @param {FuelPriceCardProps} props 
*/
export default function FuelPriceCard (props) {

    props = {
        variant: "full",
        ...props
    }

    const [data, setData] = useState(/** @type {import("../api/LogitarApiTypes").FuelPriceRow[]} */([]));
    const [count, setCount] = useState(0);

    useEffect(() => {
        LogitarApi.getFuelPrices(props.fuelType)
            .then((resp) => {
                if(resp.status) {
                    setData(resp.fuelPrices);
                }
                else {

                }
            })
            .catch((err) => {

            })
    }, [count, props.fuelType])

    if(props.variant === "small" && !props.fuelType) {
        throw "Variant 'small' requires fuelType prop!";
    }

    if(props.variant === "small") {

        const rows = data.sort((a, b) => {
            return (a.pricePerLitre || 10000) - (b.pricePerLitre || 10000);
        })

        return <Card sx={{ p: 1, mr: 2, mb: 2, height: 'auto', ...props.sx }} className="fuel-card">
            <Typography variant="caption" sx={{fontWeight: 'bold'}}>{fuelTypeList.find(y => y.value === props.fuelType).label}</Typography>
            <Divider sx={{mt: 1}} />
            <Table>
                <TableBody>
                    {
                        vendorList.map((e, i) => {
                            const r = rows.findIndex(y => y.vendor == e.value);
                            return <TableRow key={"fuelprice-" + i}>
                                <TableCell variant="head">{e.label}</TableCell>
                                <TableCell>
                                    <FuelPriceField 
                                        fuelType={props.fuelType}
                                        vendor={e.value}
                                        editable={props.editable}
                                        className={r >= 0 ? fuelPriceThemes[r] : ''}
                                        data={data}
                                        onValueUpdated={() => setCount(prev => prev + 1)}
                                    />
                                </TableCell>
                            </TableRow>
                         })
                    }
                </TableBody>
            </Table>
        </Card>
    }
    else if(props.variant === "colors") {
        // Display ONLY colors, no values
        const rows = data.sort((a, b) => {
            return (a.pricePerLitre || 10000) - (b.pricePerLitre || 10000);
        })

        let vendorRows = data.map((e) => {
            const vndr = vendorList.find(y => e.vendor === y.value);
            return vndr;
        })
        
        // vendorList.forEach(e => {
        //     if(vendorRows.findIndex(y => e.value === y.value) < 0) {
        //         vendorRows.push(e);
        //     }
        // })

        if (props.fuelType) vendorRows = vendorRows.filter(row => row.fuelTypes.includes(props.fuelType));

        return <Card sx={{ p: 1, mr: 2, mb: 2, height: 'auto', ...props.sx }} className="fuel-card">
            <Typography variant="caption" sx={{fontWeight: 'bold'}}>{fuelTypeList.find(y => y.value === props.fuelType).label}</Typography>
            <Divider sx={{mt: 1}} />
            <Table>
                <TableBody>
                    { props.horizontal ?
                        <TableRow>
                            {
                                vendorRows.map((e, i) => {
                                    return <TableCell
                                        sx={{
                                            p: 0.3,
                                            border: 0,
                                        }}
                                    >
                                        <span
                                            style={{
                                                padding: "10px", borderRadius: "5px",
                                                display: 'flex'
                                            }}
                                            className={fuelPriceThemes[i]}
                                        >
                                            {e.label}
                                        </span>
                                    </TableCell>
                                })
                            }
                        </TableRow>
                    :
                        vendorRows.map((e, i) => {
                            return <TableRow key={"fuelprice-" + i}>
                                <TableCell
                                    sx={{
                                        p: 0.3
                                    }}
                                >
                                    <span
                                        style={{
                                            padding: "10px", borderRadius: "5px",
                                            display: 'flex'
                                        }}
                                        className={fuelPriceThemes[i]}
                                    >
                                        {e.label}
                                    </span>
                                </TableCell>
                            </TableRow>
                        })
                    }
                </TableBody>
            </Table>
        </Card>
    }

    return <Card sx={{ p: 1, mr: 2, mb: 2, height: 'auto', ...props.sx }} className="fuel-card">
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography variant="h6">{"Polttoaineen hinnat"}</Typography>
            <Typography variant="caption" sx={{ display: 'flex', alignItems: 'flex-end' }} >
                {format(date, "dd.MM.yyyy")}
            </Typography>
        </Box>
        <Divider sx={{ my: 1 }} />
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell size="small">

                    </TableCell>
                    {
                        vendorList.map((e, i) => {
                            return <TableCell key={"pv-" + i} size="small">
                                {e.label}
                            </TableCell>
                        })
                    }
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    (props.fuelType ? fuelTypeList.filter(e => e.value === props.fuelType) : fuelTypeList).map((e, i) => {
                        let rows = data.filter(y => y.fuelType == e.value);

                        rows = rows.sort((a, b) => {
                            return (a.pricePerLitre || 10000) - (b.pricePerLitre || 10000);
                        })
                        
                        return <TableRow key={"ph-" + i} size="small">
                            <TableCell variant="head">
                                {e.label}
                            </TableCell>
                            {
                                vendorList.map((ex, ix) => {
                                    const r = rows.findIndex(y => y.vendor == ex.value);
                                    return <TableCell key={"pf-" + i + "-" + ix} size="small">
                                        <FuelPriceField 
                                            fuelType={e.value}
                                            vendor={ex.value}
                                            editable={props.editable}
                                            className={r >= 0 ? fuelPriceThemes[r] : ''}
                                            data={data}
                                            onValueUpdated={() => setCount(prev => prev + 1)}
                                        />
                                    </TableCell>
                                })
                            }

                        </TableRow>
                    })
                }
            </TableBody>
        </Table>
    </Card>
}

/**
 * 
 * @param {{editable?: boolean, fuelType: FuelType, vendor: FuelVendor, className: string, data: import("../api/LogitarApiTypes").FuelPriceRow[], onValueUpdated?: () => void}} props 
 */
function FuelPriceField(props) {

    const row = props.data.find(e => e.fuelType == props.fuelType && e.vendor == props.vendor);
    // const [value, setValue] = useState(row && row.date === format(date, "yyyy-MM-dd") ? row.pricePerLitre : "");
    const [value, setValue] = useState(row && row.pricePerLitre);
    const initialModified = useRef(false);

    useEffect(() => {
        // setValue(row && row.date === format(date, "yyyy-MM-dd") ? row.pricePerLitre : "");
        setValue(row && row.pricePerLitre)
    }, [props.data])

    const onEndEdit = () => {
        let del = false;

        if (!initialModified.current) {
            // Fuel value not modified yet
            return;
        }
        // Delete existing price for today when entering empty value
        else if (value === "" && row && row.id && row.date === format(date, "yyyy-MM-dd")) {
            del = true;
        }
        else if(!value || (row && Number(row.pricePerLitre) === Number(value))) {
            console.log("invalid fuel value");
            return;
        }

        let nval = value;
        let nr = null;
        let isNewRow = false;
        
        if (del) {
            nr = row;
        } else {
            if(value != null && value instanceof String) {
                nval = Number(value.replace(",", "."));
            }
            
            if(row && row.date === format(date, "yyyy-MM-dd")) {
                nr = {...row, pricePerLitre: nval};
            }
            else {
                nr = {
                    fuelType: props.fuelType,
                    date: format(date, "yyyy-MM-dd"),
                    vendor: props.vendor,
                    pricePerLitre: nval
                }
                isNewRow = true;
            }
        }
        
        LogitarApi.setFuelPrices(nr, del)
            .then((resp) => {
                if(resp.status) {
                    enqueueSnackbar("Polttoaineen hinta " + (del ? "poistettu" : "päivitetty"));
                    if (props.onValueUpdated) props.onValueUpdated();
                }
                else {
                    enqueueSnackbar("Polttoaineen hinnan päivitys epäonnistui", { variant: 'error' });
                }
            })
            .catch((err) => {
                enqueueSnackbar("Polttoaineen hinnan päivitys epäonnistui", { variant: 'error' });
            })
    } 

    let content = null;

    if(props.editable) {
        content = <TextField 
            type="number"
            inputProps={{
                style: {
                    margin: 0,
                    padding: 5,
                    width: 60,
                    color: row && row.date !== format(date, "yyyy-MM-dd") ? logitarThemeMode === "dark" ? "black" : "white" : '',  // Korjattu ehdollinen värin määritys                
                },
                className: props.className || '',
                min: 0,
                step: 0.001,
                lang: "fi-FI",
            }}
            autoComplete="off"
            value={value}
            //placeholder={row && row.date !== format(date, "yyyy-MM-dd") ? row.pricePerLitre.toString().replace(".", ",") : ""}
            onChange={(e) => {
                setValue(e.target.value);
                initialModified.current = true;
            }}
            onBlur={() => {
                onEndEdit();
            }}
        />
    }
    else {
        content = <span style={{padding: 10, borderRadius: 5}} className={props.className || ''}>
            {row ? Number(row.pricePerLitre).toFixed(3).replace(".", ",") : ""}
        </span>
    }

    let ttText = "";
    if(row && row.date !== format(date, "yyyy-MM-dd")) {
        ttText = `Viimeksi päivitetty ${format(new Date(row.date), "dd.MM.yyyy")}`;
    }
    else if(row) {
        ttText = `Päivitetty tänään`;
    }

    return <Tooltip title={ttText}>
        {content}
    </Tooltip>
}