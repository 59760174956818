import { Autocomplete, Box, BoxProps, Button, LinearProgress, ListItemText, ListItemIcon, MenuItem, Popover, PopoverPosition, Slider, Stack, Switch, SxProps, TextField, Tooltip, Typography, SvgIcon, Menu, useMediaQuery, useTheme, IconButton, CircularProgress } from "@mui/material";
import React, { useState, useCallback, useContext, useRef, memo, useEffect, ChangeEvent, KeyboardEvent } from 'react';
import { addDays, format, intervalToDuration, isBefore, isSameDay } from "date-fns";
import { Clear, Undo, CallSplit, Save, Send, Cancel, Delete } from '@mui/icons-material';
import DescriptionIcon from '@mui/icons-material/Description';
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { fi } from "date-fns/locale";
import { useSnackbar } from 'notistack';
import LogitarApi from "../api/LogitarApi";
import { LogitarItem } from "../api/LogitarApiTypes";
import { CustomPopper, TipButton, getPayTimeframe, getTime } from "../misc/InternalFeatures";
import { DragItem, ItemFish, JobInfoJobFish, PlanningItem, PlanningJobInfo, PlanningJobSplit, PlanningOrder, PlanningRow, PlanningSlot, WorkHours, WorkHoursRow } from "../misc/LogitarTypes";
import { PLANNING_SLOT_INITIAL_COUNT, SendingContext, UpdateContext } from "../views/Planning";
import AlertPop from "./AlertPop";
import PlanningDriverSelector from "./PlanningDriverSelector";
import PlanningSlotTooltip from './PlanningSlotTooltip';
import { formatJobFishData } from "./fish/FishHelpers";
import FishIcon from "../assets/FishIcon";

const carHeaderStyle: SxProps = {
    minWidth: 130,
    fontSize: 12,
    mt: 1,
    mr: 1,
    fontWeight: 'bold',

}

const carTextStyle: any = {
    mb: 0,
    width: 110,
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
            borderColor: "black"
        }
    },
    height: 48,
    mr: 0
}

const rowStyle: SxProps = {
    display: 'flex',

    flexDirection: "row",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    height: "auto",
    gap: "4px 8px",
}

const itemOptionStyle: SxProps = {
    flex: 1,
    maxWidth: 800,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
}

const normalize = (n: number) => {
    if (n > 12) {
        return 100;
    }
    return n / 12 * 100;
}

export const logoPaths: { [key: string]: { path: string; style?: any; }; } = {
    "softrain": {
        path: "./logos/logo-softrain.png",
    },
    "konnekuljetus": {
        path: "./logos/logo-konnekuljetus.png"
    },
    "tarkkala": {
        path: "./logos/logo-tarkkala.svg"
    },
    "metsanen": {
        path: "./logos/logo-metsanen.svg"
    }
}

export const logoPathsSmall: { [key: string]: { path: string; style?: any; }; } = {
    "softrain": {
        path: "./logos/small/logo-softrain-small.png"
    },
    "konnekuljetus": {
        path: "./logos/small/logo-konnekuljetus-small.png"
    },
    "tarkkala": {
        path: "./logos/small/logo-tarkkala-small.svg"
    },
    "metsanen": {
        path: "./logos/small/logo-metsanen-small.svg"
    }
}

export const fishReqs: { [key: string]: { inJob: keyof JobInfoJobFish, label: string } } = {
    "reqLoadTime": { inJob: "loadTime", label: "Lastauksen kesto" },
    "reqUnloadTime": { inJob: "unloadTime", label: "Purun kesto" },
    "reqWashTime": { inJob: "washTime", label: "Pesun kesto" },
    "reqWashLocation": { inJob: "washLocation", label: "Pesupaikka" },
    "reqFishStatus": { inJob: "fishStatus", label: "Kalojen kunto" },
}

export const reqs: { [key: string]: { inJob: string, label: string } } = {
    "reqTons": { inJob: "tons", label: "Tonnit", },
    "reqM3": { inJob: "m3", label: "Kuutiot", },
    "reqCount": { inJob: "count", label: "Kpl", },
    "reqHours": { inJob: "hours", label: "Tunnit" },
    "reqWaitTime": { inJob: "waitTime", label: "Odotus", },
    "reqKm": { inJob: "kilometres", label: "Kilometrit", },
    "reqDetails": { inJob: "jobDetails", label: "Lisätiedot" },
    "talenomVariants": { inJob: "talenomVariant", label: "Laji" },
}

function PlanningRowSlot(props: { slot: PlanningSlot, dragItem?: DragItem | null, interClient?: string, onStartDrag: (e: DragEvent) => void, onDragEnd: () => void }) {

    const { slot } = props;
    const interClient = props.slot.job?.interClient;

    const [info, setInfo] = useState<string | null>(null);
    const [job, setJob] = useState<PlanningJobInfo | null>(slot.job ? { ...slot.job } : null);

    const [isSelected, setIsSelected] = useState(false);
    const autocompleteRef = useRef<any>();
    const { enqueueSnackbar } = useSnackbar();

    const update = useContext(UpdateContext);
    const sending = useContext(SendingContext);

    const [options, setOptions] = useState<any[]>([]);
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const [contextMenu, setContextMenu] = useState<PopoverPosition | null>(null);
    const [splitting, setSplitting] = useState<PopoverPosition | null>(null);

    const [dragging, setDragging] = useState<boolean>(false);

    const searchTimeout = useRef<NodeJS.Timeout | null>(null);
    const [optionsLoading, setOptionsLoading] = useState(false);

    const theme = useTheme();

    const getInitialSplitInfo = () => {
        return {
            date: format(slot.shift === 'A' ? new Date(slot.date) : addDays(new Date(slot.date), 1), "yyyy-MM-dd"),
            shift: slot.shift === 'A' ? "I" : "A",
            hoursRatio: 50,
            switchLocation: "",
            vehicle: slot.vehicle,
        } as PlanningJobSplit
    }
    const [splitInfo, setSplitInfo] = useState<PlanningJobSplit>(getInitialSplitInfo())

    const [deleteConf, setDeleteConf] = useState(false);

    //False is not split, right is has been split to this shift, left is split from this shift 
    const [splitState, setSplitState] = useState<"left" | "right" | false>(
        job?.jobSplit?.id ?
            (job?.jobSplit.date === slot.date &&
                job?.jobSplit.shift === slot.shift) ?
                "right" : "left" : false
    )

    useEffect(() => {

        setJob(props.slot.job ? { ...props.slot.job } : null);

        if (!props.slot.job) return;

        //Handle new information passed on to this component
        const newInfo = props.slot.job

        setInfo(newInfo?.draft?.planDetails ? newInfo.draft.planDetails : newInfo.planDetails);

        const splitDelInDraft = newInfo.draft && newInfo.draft['>hasJobSplit'] === 3

        if (newInfo.jobSplit && newInfo.jobSplit.id && !splitDelInDraft) {
            setSplitInfo(newInfo.jobSplit)
            setSplitState(newInfo.jobSplit.date !== slot.date || newInfo.jobSplit.shift !== slot.shift ? "left" : "right")
        }
        else {
            setSplitInfo(getInitialSplitInfo());
            setSplitState(false);
        }

        if (sending)
            sending(() => false)

    }, [props.slot, props.slot.job, props.slot.job?.jobSplit])

    useEffect(() => {
        if (isSelected && autocompleteRef.current) {
            autocompleteRef.current.focus();
        }
    }, [isSelected])

    const clearCreatedPlan = (send: boolean, slot: PlanningSlot) => {

        if (!send) {
            setDeleteConf(false);
            return;
        }

        if (!slot.job) return;

        console.log(slot.job, interClient)

        let d: any = {
            id: slot.job.id,
            ">deleted": true
        }
        if (interClient) {
            d = { ...d,">icreset": true}
        }

        console.log(d)

        sending(() => true)

        LogitarApi.setJobs(d).then((result) => {
            setDeleteConf(false);
            if (!slot.job) return;

            console.log(result);
            update({ ...slot, ...d });
            //If clear successful, reset this slot
            if (slot.job.state === 0) {
                setJob(null);
            } else {
                setJob({ ...slot.job, draft: { ">deleted": true } });
            }
            setInfo(null);
            sending(() => false);

        })
            .catch((err) => console.error(err))
    }

    const undoDelete = () => {
        if (!job)
            return;

        let d: any = {
            id: job.id,
            ">deleted": false,
        }
        if (interClient) {
            d = { ...d,">icreset": false}
        }

        LogitarApi.setJobs(d).then((result) => {
            enqueueSnackbar("Kuorma palautettu");
        })
            .catch((err) => {
                enqueueSnackbar("Kuorman palautus epäonnistui", { variant: "error" });
            });
    }

    const undoMove = () => {
        if (!job)
            return;

        let d: any = {
            id: job.id,
            date: job.date,
            shift: job.shift,
            slot: job.slot,
            vehicle: job.vehicle
        }

        LogitarApi.setJobs(d).then((result) => {
            enqueueSnackbar("Kuorman siirto peruttu");
        })
            .catch((err) => {
                enqueueSnackbar("Kuorman siirron peruutus epäonnistui", { variant: "error" });
            });
    }

    //Send "details" as soon as its saved
    const handleInfoSave = (del: boolean = false) => {
        if (!job)
            return;

        let d: any = {
            id: job.id,
            planDetails: del ? null : info,
            ">forceUpdate": job.state === 0 ? true : false
        }

        LogitarApi.setJobs([d]).then((result) => {
            console.log(result)
            handleInfoClose()
        })
            .catch((err) => console.error(err))

    }

    const handleInfoClose = () => {
        setAnchorEl(null);
    }

    const selectChange = (event: React.DragEvent | React.ChangeEvent | null, item: DragItem | null, slot: PlanningSlot) => {

        console.log(event, item, slot);

        if (!item)
            return;

        sending(() => true);

        if (event) event.preventDefault();

        let tempPlan: any = {
            id: item.job ? item.job.id : undefined,
            date: slot.date,
            shift: slot.shift,
            item: item.item.id,
            vehicle: slot.vehicle,
            slot: slot.slot,
            ">forceUpdate": (item.job && item.job.id && item.job.state === 0 && !item.job.draft) ||
                (item.job && item.job.id && item.job.state === 0 && !item.job.vehicle) ? true : false
        }

        if (item.job && item.job.interClient) {
            delete tempPlan.item;
        }

        if (autocompleteRef.current)
            autocompleteRef.current.blur();

        LogitarApi.setJobs([tempPlan]).then((result) => {
            if (Boolean(result.isBetween)) {
                enqueueSnackbar("Luotu kuorma on jaon välissä", { variant: "warning" });
            }
            setOptions([]);
            update(tempPlan);            
        }).catch((err) => {
            console.error(err)
            if (err.error === 0x43) {
                enqueueSnackbar("Kuorma numerolla jo tietokannassa, päivitetään ruutu....", { variant: "error" });
                update(err);
            } else {
                enqueueSnackbar("Virhe tallentaessa kuormaa", { variant: "error" });
            }
        }).finally(() => {
            props.onDragEnd();
            sending(() => false);
        })
    }

    const handleRowContextMenu = (e: React.MouseEvent) => {
        e.preventDefault();
        setContextMenu({ top: e.clientY, left: e.clientX })
    }

    const searching = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

        if (e.target.value.trim() === "") {

        } else {
            if (searchTimeout.current) clearTimeout(searchTimeout.current);
            searchTimeout.current = setTimeout(() => {
                setOptionsLoading(true);
                LogitarApi.getItems({ search: String(e.target.value) })
                    .then((result) => {
                        if (result.items.length === 0) {

                        } else {
                            setOptions(result.items.map((value: LogitarItem) => {
                                return { ...value }
                            }))
                        }
                    })
                    .catch((e) => console.error(e))
                    .finally(() => {
                        setOptionsLoading(false);
                        searchTimeout.current = null;
                    })

            }, 500)

            return () => clearTimeout(searchTimeout.current || undefined)
        }

    }, [searchTimeout.current])

    const handleJobSplit = () => {
        if (!job)
            return;

        //Prohibit inserting to the same shift
        if (splitInfo.date === slot.date && splitInfo.shift === slot.shift) {
            enqueueSnackbar("Et voi jakaa kuormaa samalle vuorolle", { variant: "error" })
            return
        }

        LogitarApi.setJobSplit({ ...splitInfo, jobID: job.id, jobUser: job.user }).then((r) => {

            if (!job)
                return;

        })
            .catch((e) => {
                if (e?.reason === "jobSplitDestinationExists") {
                    enqueueSnackbar("Kuorma on jo jaettu kohde vuorolle", { variant: "error" });
                    if (e?.restore !== null) {
                        setSplitInfo(prev => ({ ...prev, date: e.restore.date, shift: e.restore.shift }))
                    } else {
                        const def = getInitialSplitInfo()
                        setSplitInfo(prev => ({
                            ...prev,
                            date: def.date,
                            shift: def.shift,    
                        }))
                    }
                }
                else if (e?.reason === "anotherSplit") enqueueSnackbar("Vuorossa voi olla vain yksi jaettu kuorma", { variant: "error" });
                else enqueueSnackbar("Kuorman jakaminen epäonnistui", { variant: "error" });
                console.error(e);
            })

        setSplitting(null)
    }

    const deleteJobSplit = () => {

        if (!job)
            return;

        if (!splitInfo.id) {
            return
        }

        LogitarApi.setJobSplit({ ...splitInfo, jobUser: job.user, jobID: job.id }, true).then((r) => {
            setSplitInfo(getInitialSplitInfo())
            console.log(r)
        })
            .catch((e) => console.log(e))
    }

    const handleJobKeyInput = (event: KeyboardEvent) => {
        if (event.key === "Tab" || event.key === "Enter") {
            event.preventDefault();
            event.stopPropagation();
            if (autocompleteRef.current.value) {
                const nextSlotExists = slot.slot < PLANNING_SLOT_INITIAL_COUNT - 1;
                const searchId = !isNaN(autocompleteRef.current.value) ? autocompleteRef.current.value : null;
                /** @ts-ignore must be ignored because getItems params are defined wrong*/
                if (searchId) LogitarApi.getItems({ id: Number(searchId) }).then(result => {
                    if (result.status && result.items.length > 0) {
                        selectChange(null, { item: result.items[0] }, slot)
                        if (nextSlotExists) {
                            const nextElement = document.evaluate(`//*[@data-testid="planning-slot"][@data-slotinfo="${slot.vehicle};${slot.slot + 1}"]`, document).iterateNext() as HTMLElement;
                            nextElement.click();
                        } else {
                            const sendButton = document.evaluate(`//*[@data-testid="send-vehicle"][@data-vehicle="${slot.vehicle}"]`, document).iterateNext() as HTMLElement;
                            sendButton.focus();
                        }
                    }
                }).catch(err => {
                    enqueueSnackbar(`Nimikettä ${searchId} ei löytynyt`, { variant: "error" });
                    console.error(err);
                });
            }
        }
    }

    const isMoved = !!job && job.draft && job.draft.vehicle && job.draft.vehicle !== slot.vehicle ? true : false;
    const isDeleted = !!job && job.draft && job.draft[">deleted"] ? true : false;

    if (!!job && !isDeleted && !isMoved) {

        let logoPath = "";
        let logoStyle = {};

        if (job.interClient && logoPathsSmall[job.interClient]) {
            logoPath = logoPathsSmall[job.interClient].path;
            logoStyle = logoPathsSmall[job.interClient].style || {};

        }

        let slotStyle = "default-red";

        if (job.state === 0 || (!!job.draft && !Array.isArray(job.draft) && Object.keys(job.draft).length > 0)) {
            // Red job if is unsent or any draft is set
            slotStyle = "default-red";
        }
        else if (job.state === 1) {
            // Yellow job if sent
            slotStyle = "default-yellow";
        }
        else if (job.state === 2) {
            // Green job if seen
            slotStyle = "default-green";
        }
        else if (job.state === 3) {
            // Blue job if loaded
            slotStyle = "default-blue";
        }
        else if (job.state === 4) {
            // Grey job if completed
            slotStyle = "";
        }

        // carTextStyle can't be null but TS seems to think it can be
        if (!carTextStyle)
            return null;

        const isDraggable = !(splitState !== false);
        const isFishItem = job.item.itemType === "fish";
        const jobFishFormatted = formatJobFishData(job.jobFish);

        return (
            <Box
                sx={{
                    ...carTextStyle, display: "flex", flexDirection: "row", pr: 0, position: "relative",
                    "& .MuiFormHelperText-root": { width: "130px", ml: 0.5 },
                    "& .MuiOutlinedInput-root": { pr: 0.5 },
                }}
                onContextMenu={handleRowContextMenu}
                component={"div"}
            >
                <Tooltip sx={{ fontSize: 22 }} placement='right' title={!dragging ? <PlanningSlotTooltip slot={slot}/> : ''} 
                    slotProps={{ tooltip: { sx: { maxWidth: "320px" }, onContextMenu: (e) => e.stopPropagation() }}}>
                    <Box>
                        {
                            isFishItem && <Box sx={{ position: "absolute", top: 1, right: 24, bottom: 1, left: 1, borderRadius: 1, overflow: "hidden", zIndex: 1, pointerEvents: "none" }}>
                                <FishIcon sx={{ width: "125%", height: "125%", transform: "translate(-10%, 10%)", opacity: 0.2 }} />
                            </Box>
                        }
                        {(splitState === "left" || splitState === "right") &&
                            <Box
                                sx={{
                                    position: "absolute",
                                    left: splitState === "left" ? 0 : splitState === "right" ? -1 : undefined,
                                    background: theme.palette.mode === "light" ? "#888" : "#ccc",
                                    borderRadius: 1,
                                    px: 0.5,
                                    py: 0.1,
                                    height: carTextStyle.height,
                                    width: carTextStyle.width - 23,
                                    clipPath: splitState === "left"
                                        ? "polygon(50% 0%, 90% 0%,99% 50%, 90% 101%,50% 101%, 0% 101%,0% 50%, 0% 0%)" :
                                        splitState === "right" ?
                                            "polygon( 50% 0%, 101% 0%, 101% 50%, 101% 101%, 50% 101%, 10% 101%, 1% 50%,  10% 0%)" : "",
                                }}
                            />
                        }
                        <Box
                            data-testid="planning-slot"
                            data-slotinfo={`${slot.vehicle};${slot.slot}`}
                            sx={{
                                zIndex: 1,
                                background: job.state > 3 ? theme.palette.background.paper : undefined,
                                backgroundImage: job.state > 3 && theme.palette.mode === "dark" ? // Match the paper background
                                    "linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1))" : undefined,
                                borderWidth: 1,
                                borderColor: theme.palette.mode === "light" ? "#888" : "#ccc",
                                borderStyle: 'solid',
                                borderRadius: 1,
                                px: 0.5,
                                py: 0.1,
                                height: carTextStyle.height,
                                width: carTextStyle.width - 24,
                                flexDirection: "row",
                                display: "flex",
                                textAlign: 'center',
                                clipPath: splitState === "left"
                                    ? "polygon(50% 0%, 90% 0%,99% 50%, 90% 100%,50% 100%, 0% 100%,0% 50%, 0% 0%)" :
                                    splitState === "right" ?
                                        "polygon( 50% 0%, 100% 0%, 100% 50%, 100% 100%, 50% 100%, 10% 100%, 1% 50%,  10% 0%)" : "",
                            }}
                            className={!isDeleted ? slotStyle : "default-red"}
                            draggable={isDraggable}
                            onDragStart={(e) => {
                            if(!isDraggable) return;

                                setDragging(true);

                                props.onStartDrag(e as unknown as DragEvent)
                            }}
                            onDragEnd={() => {
                                setDragging(false);
                                props.onDragEnd();
                            }}
                        >
                            <Typography
                                sx={{ fontSize: "0.68rem", overflow: "hidden", overflowWrap: "break-word", cursor: "pointer", zIndex: 1 }}
                                onClick={() => {
                                    if(job.icItemAlias) {
                                        // Open new tab in `items?id=${pInfo.item.id}`
                                        window.open(`items?id=${job.icItemAlias.id}`, "_blank");
                                    }
                                    else {
                                        // Open new tab in `items?id=${pInfo.item.id}`
                                        window.open(`items?id=${job.item.id}`, "_blank");
                                    }
                                }}
                            >
                                {
                                    (isDeleted || !job.item.active) ?
                                        <s>
                                            {job.icItemAlias ? `${job.icItemAlias.id}:${job.icItemAlias.shortName}` : `${job.item.id}:${job.item.shortName}`}
                                        </s>
                                        :
                                        <>
                                            {job.icItemAlias ? `${job.icItemAlias.id}:${job.icItemAlias.shortName}` : `${job.item.id}:${job.item.shortName}`}
                                        </>
                                }
                            </Typography>

                            {
                                job.interClient &&
                                <img
                                    src={logoPath}
                                    style={{ width: 14, height: 14, position: 'absolute', right: splitState === "left" ? 33 : 27, bottom: 3,
                                        opacity: 0.75, objectFit: 'contain', objectPosition: 'bottom right' }}
                                    alt={job.interClient}
                                />
                            }
                        </Box>
                    </Box>
                </Tooltip>
                <Box sx={{ display: "flex", flexDirection: "column" }}>

                    {splitState !== 'right' &&
                        <Clear
                            data-testid="delete-job"
                            sx={{ cursor: "pointer" }}
                            onClick={job.state > 2 ? () => setDeleteConf(true) : () => clearCreatedPlan(true, slot)}
                        />
                    }

                    <div className={info && info.length > 0 ? 'default-green' : ""} style={{ borderRadius: 3, height: "50%" }}>
                        <DescriptionIcon onClick={(e) => { setAnchorEl(e.currentTarget) }} sx={{ cursor: "pointer" }}></DescriptionIcon>
                    </div>

                </Box>

                <Popover
                    sx={{ ml: 3, borderRadius: 1 }}
                    open={anchorEl !== null}
                    anchorEl={anchorEl}
                    anchorReference="anchorEl"
                    onClose={handleInfoClose}
                    slotProps={{
                        root: {
                            onContextMenu: (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleInfoClose();
                            }
                        }, paper: {
                            onContextMenu: (e) => {
                                e.stopPropagation();
                            }
                        }
                    }}
                >
                    <Stack direction={"column"} gap={1} sx={{ px: 2, pt: 1, pb: 1.5, width: 450, position: "relative" }}>
                        <Typography variant="h6">Kuorman lisätiedot</Typography>
                        <IconButton sx={{ position: "absolute", top: 8, right: 8 }} onClick={() => handleInfoClose()}>
                            <Clear />
                        </IconButton>
                        <TextField onChange={(e) => setInfo(e.target.value)} value={info || ""} multiline rows={3} sx={{ pb: 1, width: "100%" }} />
                        <Box sx={{ display: "flex", flexDirection: "row", gap: 2, justifyContent: "space-between" }}>
                            <Button variant='contained' fullWidth color="inherit" startIcon={<Cancel />} onClick={() => handleInfoClose()}>Peruuta</Button>
                            <Button variant='contained' fullWidth color="error" startIcon={<Delete />} onClick={() => handleInfoSave(true)}>Poista</Button>
                            <Button variant='contained' fullWidth color="primary" startIcon={<Save />} onClick={() => handleInfoSave()}>Tallenna</Button>
                        </Box>
                    </Stack>
                </Popover>

                <Menu
                    onClose={() => {
                        setContextMenu(null);

                    }}
                    anchorReference={"anchorPosition"}
                    open={contextMenu !== null}
                    anchorPosition={contextMenu || undefined}
                    slotProps={{
                        root: {
                            onContextMenu: (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setContextMenu(null);
                            }
                        }, paper: {
                            onContextMenu: (e) => {
                                e.stopPropagation();
                            }
                        }
                    }}
                >
                    <MenuItem disabled={isFishItem} onClick={(e) => { setSplitting({ top: e.clientY, left: e.clientX }); setContextMenu(null) }} >
                        <ListItemIcon>
                            <CallSplit sx={{ transform: "rotate(90deg)" }} ></CallSplit>
                        </ListItemIcon>
                        <ListItemText>Jaa</ListItemText>
                    </MenuItem>
                    <MenuItem disabled={splitState !== 'left'} onClick={() => { setContextMenu(null); deleteJobSplit() }}>
                        <ListItemIcon>
                            <Clear sx={{ color: "red" }}></Clear>
                        </ListItemIcon>
                        <ListItemText>Poista jako</ListItemText>
                    </MenuItem>
                </Menu>

                <Popover
                    onClose={() => {
                        setSplitting(null);

                    }}
                    anchorReference={"anchorPosition"}
                    open={splitting !== null}
                    anchorPosition={splitting || undefined}
                    slotProps={{
                        root: {
                            onContextMenu: (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setSplitting(null);
                            }
                        }, paper: {
                            onContextMenu: (e) => {
                                e.stopPropagation();
                            }
                        }
                    }}
                >
                    <Stack sx={{ px: 2.5, width: "400px", py: 1, gap: 1, position: "relative" }}>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography variant="h6">Kuorman jakaminen</Typography>
                            <IconButton sx={{ position: "absolute", top: 8, right: 8 }} onClick={() => setSplitting(null)}>
                                <Clear />
                            </IconButton>
                        </Box>
                        <Typography>{job.item.id}:{job.item.name}</Typography>
                        <Box sx={{ display: "flex", flexWrap: "nowrap", justifyContent: "space-between", mt: 1, gap: 1 }} >
                            <LocalizationProvider adapterLocale={fi} dateAdapter={AdapterDateFns}>
                                <Stack>
                                    <DesktopDatePicker
                                        format='dd.MM.yyyy'
                                        label="Lastauspäivä"
                                        value={splitState === "right" && job.date ? new Date(job.date) : new Date(slot.date)}
                                        disabled
                                        sx={{ height: 30, "& .MuiOutlinedInput-root ": { height: 30 } }}
                                    ></DesktopDatePicker>

                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Typography>Aamu</Typography>
                                        <Switch disabled checked={splitState === "right" && job.shift ? job.shift === "I" : slot.shift === "I"}></Switch>
                                        <Typography>Ilta</Typography>
                                    </Box>

                                </Stack>
                                <Stack>

                                    <DesktopDatePicker
                                        format='dd.MM.yyyy'
                                        label="Purkupäivä"
                                        value={new Date(splitInfo.date)}
                                        disabled={splitState === "right"}
                                        minDate={slot.shift === "I" ? addDays(new Date(slot.date), 1) : new Date(slot.date)}
                                        onChange={(v) => {
                                            if (!v || !v.getTime())
                                                return

                                            let shift: "A" | "I" = splitInfo.shift

                                            // Stop shift from being the same as jobs shift
                                            if (!!job.date && isSameDay(new Date(job.date), v)) {
                                                shift = "I"
                                            }

                                            setSplitInfo(prev => ({ ...prev, shift: shift, date: format(v, "yyyy-MM-dd") }))

                                        }}
                                        sx={{ height: 30, "& .MuiOutlinedInput-root ": { height: 30 } }}
                                    ></DesktopDatePicker>

                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Typography>Aamu</Typography>
                                        <Switch disabled={splitState === "right"} checked={splitInfo.shift === "A" ? false : true} onChange={(e) => {

                                            // If splits date is the same as jobs, then the shift can only by "I" if the jobs shift is "A"                                            
                                            if (!!job.date &&
                                                (format(new Date(splitInfo.date), "yyyy-MM-dd") === format(new Date(job.date), "yyyy-MM-dd") &&
                                                    (splitInfo.shift === "I" && !e.target.checked) || (splitInfo.date === "A" && !e.target.checked))) {
                                                enqueueSnackbar("Et voi jakaa kuormaa samalle vuorolle", { variant: "error" })
                                                setSplitInfo(prev => ({ ...prev, shift: "I" }))
                                                return
                                            }

                                            setSplitInfo(prev => ({ ...prev, shift: e.target.checked ? "I" : "A" }))
                                        }}
                                        ></Switch>
                                        <Typography>Ilta</Typography>
                                    </Box>
                                </Stack>
                            </LocalizationProvider>
                        </Box>
                        <Box>
                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                <Tooltip title={"Nimikkeen keston prosenttijakauma"}>
                                    <div>{`${splitInfo.hoursRatio}%`}</div>
                                </Tooltip>
                                <Tooltip title={"Nimikkeen kokonaiskesto"}>
                                    <div>{getTime(Number(job.item.hours))}</div>
                                </Tooltip>
                            </Box>
                            <Slider
                                disabled={splitState === "right"}
                                value={splitInfo.hoursRatio}
                                onChange={(e: Event, value: number | number[]) => {
                                    if (typeof value === "number")
                                        setSplitInfo(prev => ({ ...prev, hoursRatio: value }));
                                }}
                                size="small"
                            />
                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                <Tooltip title={"Viejän tunnit"}>
                                    <div>{getTime(Number(job.item.hours) * splitInfo.hoursRatio / 100)}</div>
                                </Tooltip>
                                <Tooltip title={"Hakijan tunnit"}>
                                    <div>{getTime(Number(job.item.hours) - Number(job.item.hours) * splitInfo.hoursRatio / 100)}</div>
                                </Tooltip>
                            </Box>
                        </Box>
                        <TextField
                            label="Kuljettan vaihtopaikka"
                            value={splitInfo.switchLocation}
                            disabled={splitState === "right"}
                            onChange={(e) => setSplitInfo(prev => ({ ...prev, switchLocation: e.target.value }))}>
                        </TextField>
                        <Box sx={{ display: "flex", justifyContent: "space-between", color: theme.palette.text.primary }}>
                            <Button
                                startIcon={<Cancel />}
                                color="inherit"
                                onClick={() => setSplitting(null)}
                            >Peruuta</Button>
                            <Button
                                disabled={splitState === "right"}
                                startIcon={<Save />}
                                onClick={() => handleJobSplit()}
                            >Tallenna</Button>
                        </Box>
                    </Stack>
                </Popover>
                {deleteConf &&
                    <AlertPop
                        stopContextMenuPropagate
                        onClose={(action) => clearCreatedPlan(action, slot)}
                        cancellable={true}
                        title={"Haluatko varmasti poistaa kuorman?"}
                        content={
                            <div>
                                <PlanningSlotTooltip slot={slot}/>
                            </div>
                        }
                    />
                }
            </Box>
        )
    } else {

        let styleClass = "default-red";        
        if (!isSelected) {

            return (
                <Box sx={{
                    display: "flex", flexDirection: "row"
                }}>

                    <Box
                        data-testid="planning-slot"
                        data-slotinfo={`${slot.vehicle};${slot.slot}`}
                        sx={{
                            ...carTextStyle, pr: 0,
                            "& .MuiFormHelperText-root": { width: "130px", ml: 0.5 },
                            "& .MuiOutlinedInput-root": { pr: 0 },
                            borderRadius: "4px",
                            textAlign: 'center',
                            borderWidth: 1,
                            borderColor: !!job ? (theme.palette.mode === "light" ? "#888" : "#ccc") : "rgba(0,0,0,0.33)",
                            borderStyle: isMoved ? 'dashed' : 'solid',
                            width: !!job ? carTextStyle.width - 24 : carTextStyle.width,

                        }}
                        onDrop={(e) => {                              
                            e.preventDefault();
                            selectChange(e, props.dragItem || null, slot);
                        }}

                        onDragOver={(e) => {
                            if (!props.dragItem) return;
                            //Default dragOver function to prevent it from making onDrop function work
                            let event = e;
                            event.stopPropagation();
                            event.preventDefault();
                        }}
                        onClick={(e) => {
                            setIsSelected(true);
                        }}
                        onDragEnd={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            props.onDragEnd();
                        }}

                        className={!job ? "" : styleClass}
                    >

                        {
                            !!job &&
                            <Typography sx={{ fontSize: "0.68rem", overflow: "hidden", height: "48px", overflowWrap: "break-word" }} >
                                {isMoved && "(Siirretty) "}
                                <s>
                                    {job.icItemAlias ? `${job.icItemAlias.id}:${job.icItemAlias.shortName}` : `${job.item.id}:${job.item.shortName}`}
                                </s>
                            </Typography>
                        }


                    </Box>
                    {
                        !!job &&
                        <div style={{width: "24px"}}>
                            {
                            (isDeleted || isMoved) &&
                            <Tooltip placement="right" disableInteractive title={(isDeleted && !isMoved) ? "Kumoa poisto" : isMoved ? "Kumoa siirto" : ""}>
                                <Undo
                                    data-testid="undo-delete"
                                    sx={{ cursor: "pointer" }}
                                    onClick={(isDeleted && !isMoved) ? () => undoDelete() : isMoved ? () => undoMove() : undefined}
                                />
                            </Tooltip>
                            }
                        </div>

                    }
                </Box>
            )
        }
        return (
            <Autocomplete
                data-testid="planning-slot"
                data-slotinfo={`${slot.vehicle};${slot.slot}`}
                options={optionsLoading ? [{ id: -1 }, ...options] : options}
                freeSolo
                disableClearable={true}
                getOptionLabel={(option: LogitarItem | string) => typeof option !== 'string' ? `${option.id} ${option.name}` : ""}
                PopperComponent={CustomPopper}
                size="small"
                filterOptions={(o) => o} // Disable filtering, handled by API call search param

                onChange={(e, values) => {
                    if (typeof values !== "string") selectChange(e as ChangeEvent<Element>, { item: values as any }, slot)
                }}
                componentsProps={{
                    paper: {
                        elevation: 10,
                    },
                }}

                renderOption={(props, option, state, ownerState) => {
                    if (typeof option === "string") return null;
                    return (
                    option.id < 0 ?
                    <Box {...props as BoxProps} component="li" style={{ padding: 0 }}
                        sx={{ pointerEvents: "none", position: "absolute", top: 0, right: 0, left: 0 }}>
                        <LinearProgress sx={{ width: "100%" }} />
                    </Box>
                    :
                    <Box
                        {...props as BoxProps}
                        component="div"
                        sx={option.active ? {} : { opacity: 0.5, textDecoration: "line-through", position: "relative" }}
                    >
                        <Box title={`${ownerState?.getOptionLabel(option)} ${option.cargoLabel} ${option.itemNumber}`}
                            component="span" sx={{ ...itemOptionStyle }}>{ownerState?.getOptionLabel(option)}
                            <i style={{ marginInlineStart: "8px", opacity: 0.6 }}>{`${option.cargoLabel} ${option.itemNumber}`}</i>
                        </Box>
                        {!option.active &&
                            <Box sx={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }} onClick={(e) => { window.open(`items?id=${option.id}&passive`, "_blank"); e.stopPropagation() }} />
                        }
                    </Box>
                )}}

                renderInput={(params) =>
                    <TextField
                        {...params}
                        onChange={searching}

                        InputProps={{
                            ...params.InputProps,
                            style: {
                                padding: 0,
                            },

                        }}

                        onBlur={() => setIsSelected(false)}

                        inputRef={autocompleteRef}

                        onDrop={(e) => {
                            e.preventDefault();
                            selectChange(e, props.dragItem || null, slot);
                        }}

                        onDragOver={(e) => {
                            if (!props.dragItem) return;
                            //Default dragOver function to prevent it from making onDrop function work
                            let event = e;
                            event.stopPropagation();
                            event.preventDefault();
                        }}
                        type="search"
                        sx={{ ...carTextStyle }}
                        size='small'

                        onKeyDown={handleJobKeyInput}
                    />}
            />
        )
    }
}

const MemoizedSlot = memo(PlanningRowSlot, (prev, next) => {
    if (prev.dragItem !== next.dragItem) return false;
    if (!prev.slot.job && !next.slot.job) return true;
    if (!prev.slot.job || !next.slot.job) return false;

    return prev.slot.job.state === next.slot.job.state &&
        prev.slot.date === next.slot.date &&
        prev.slot.shift === next.slot.shift &&
        prev.slot.job.draft === next.slot.job.draft;
});

export interface PlanningCarRowProps {
    planRowInfo: PlanningRow;
    dragItem?: DragItem | null;
    interClient?: string;
    onStartDrag: (e: DragEvent, slot: PlanningSlot) => void;
    onDragEnd: () => void;
}


function PlanningCarRow(props: PlanningCarRowProps) {

    const [hours, setHours] = useState({ total: 0.0, completed: 0.0 });
    const [planRow, setPlanRow] = useState(props.planRowInfo);
    const update = useContext(UpdateContext);

    const theme = useTheme();
    const lgBreakPoint = useMediaQuery(theme.breakpoints.up("lg"));
    const smBreakPoint = useMediaQuery(theme.breakpoints.up("sm"));

    const [loadingShiftWorkHours, setLoadingShiftWorkHours] = useState(false);
    const [shiftWorkHours, setShiftWorkHours] = useState<WorkHours>({ user: planRow.vehicle.currentUser, name: planRow.vehicle.currentUserName, workhours: [] });
    const [workHoursData, setWorkHoursData] = useState<WorkHoursRow[] | null>(null);

    const getModifiedValue = () => {
        let modified = false;
        planRow.slots.forEach((slot) => {
            if (slot.job !== null) {
                if (slot.job.state > 0) {
                    if (slot.job.draft && !Array.isArray(slot.job.draft) && Object.keys(slot.job.draft).length > 0) {
                        modified = true;
                    }
                } else {
                    modified = true;
                }
            }
        })
        return modified;
    }
    const [modified, setModified] = useState(getModifiedValue());

    //Send cars plans
    const sendCars = () => {

        LogitarApi.setJobs(undefined, { ...planRow.shiftInfo, vehicle: planRow.vehicle.id }).then((r) => {
            console.log(r);
        })
            .catch((e) => console.error(e))
    }

    useEffect(() => {
        let hoursTemp = { total: 0, completed: 0 }
        const planRowInfo = props.planRowInfo;
        planRowInfo.slots.filter((slot) => !!slot.job).forEach((slot) => {
            if (!slot.job) return;
            let hoursToAdd = Number(slot.job.item.hours);
            if (slot?.job?.jobSplit?.id && slot?.job?.jobSplit?.hoursRatio && slot?.job?.draft?.[">hasJobSplit"] !== 3) {
                // Split right
                if (slot.job.jobSplit.date === slot.date && slot.job.jobSplit.shift === slot.shift) {
                    hoursToAdd = Number(slot.job.item.hours) * (1 - (Number(slot.job.jobSplit.hoursRatio) / 100));
                } // Split left
                else {
                    hoursToAdd = Number(slot.job.item.hours) * (Number(slot.job.jobSplit.hoursRatio) / 100);
                }
            }
            hoursTemp.total += hoursToAdd;
            if (slot.job.state === 4) {
                hoursTemp.completed += hoursToAdd;
            }
        })

        setHours(() => hoursTemp)
        setPlanRow(planRowInfo);
        setModified(getModifiedValue());

        // Get this shift's and vehicle's WorkHours data
        if (planRow?.slots?.some(slot => slot.job !== null) && planRow?.shiftInfo?.date && planRow?.shiftInfo?.shift && planRow?.vehicle?.id) {
            setLoadingShiftWorkHours(true);
            LogitarApi.getWorkHours(null, null, null, null, new Date(planRow.shiftInfo.date), planRow.shiftInfo.shift, planRow.vehicle.id)
            .then((res) => {
                if (res.status === true && res?.workhours?.length > 0) {
                    setShiftWorkHours(res.workhours[0]);
                }
            }).catch(err => console.error(err)).finally(() => setLoadingShiftWorkHours(false));
        }
    }, [props.planRowInfo])
    
    useEffect(() => {
        // Get drivers total WorkHours data
        if (shiftWorkHours.user && planRow?.shiftInfo?.date) {
            const payTimeframe = getPayTimeframe(new Date(planRow.shiftInfo.date));
            LogitarApi.getWorkHours(payTimeframe.from, payTimeframe.to, shiftWorkHours.user)
                .then((res) => {
                    const whRes = res;
                    setWorkHoursData(whRes?.workhours?.[0]?.workhours);
                }).catch(err => console.error(err));
        }
    }, [shiftWorkHours])

    const zeroPad = (num: number) => String(num).padStart(2, '0')

    let payTimeframeHours = null;
    if (workHoursData) {
        const totalHours = workHoursData.reduce((p, c) => p += c.workHours, 0);
        const hours = zeroPad(Math.floor(totalHours));
        const minutes = zeroPad(Math.round((totalHours % 1) * 60));
        const timeString = `${hours}:${minutes}h`;
        payTimeframeHours = (<span style={{ color: totalHours > 80 ? theme.palette.error.main : undefined }}>{timeString}</span>);
    }

    let workHoursTime = null;
    let workHoursTooltip = null;
    if (shiftWorkHours?.workhours?.length > 0 && shiftWorkHours.workhours[0]?.startTime) {
        const startTime = new Date(shiftWorkHours.workhours[0].startTime);
        let wht: Duration | null = null;
        if (shiftWorkHours.workhours[0]?.endTime) {
            const endTime = new Date(shiftWorkHours.workhours[0].endTime);
            wht = intervalToDuration({ start: startTime, end: endTime });
            workHoursTooltip = (<ul style={{ padding: 0, margin: 0, listStyleType: "none" }}>
                <li>Tämä vuoro / Koko jakso</li>
                <li>Vuoron alku: {format(startTime, "dd.MM HH:mm")}</li>
                <li>Vuoron loppu: {format(endTime, "dd.MM HH:mm")}</li>
            </ul>);
        }
        else {
            wht = intervalToDuration({ start: startTime, end: new Date() });
            workHoursTooltip = (<ul style={{ padding: 0, margin: 0, listStyleType: "none" }}>
                <li>Tämä vuoro / Koko jakso</li>
                <li>Vuoron alku: {format(startTime, "dd.MM HH:mm")}</li>
                <li>Vuoron loppu: -</li>
            </ul>);
        }
        if (wht)
            workHoursTime = `${zeroPad(wht.hours || 0)}:${zeroPad(wht.minutes || 0)}h`;
    }

    return (
        <Box
            sx={{
                "& .MuiLinearProgress-dashed": {
                    animation: "none"
                },
                "& 	.MuiLinearProgress-bar1Buffer ": {
                    backgroundColor: "green"
                },
                "& .MuiOutlinedInput-input": {
                    py: 0,
                    pl: 1,
                    height: 40
                }
            }}>
            {planRow &&
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    {
                        <Box sx={{ flexDirection: 'column', width: "8.5rem", maxWidth: smBreakPoint ? undefined : 100, display: 'flex', flex: 0.5 }}>
                            <Typography sx={carHeaderStyle}>
                                {planRow.vehicle.id} - {planRow.vehicle.licenseNumber}
                            </Typography>
                            <Typography sx={{ color: '#777', overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", mr: 1 }} variant="caption">
                                {
                                    loadingShiftWorkHours ?
                                        <CircularProgress size={12} color="inherit" /> :
                                        (shiftWorkHours?.user && shiftWorkHours?.name) ?
                                            <>
                                                {shiftWorkHours.user} - {shiftWorkHours.name}
                                            </>
                                            : <>-</>
                                }
                            </Typography>
                            {
                                workHoursTime &&
                                <Tooltip title={workHoursTooltip}>
                                    <Typography sx={{ color: '#777', mr: 1 }} variant="caption">
                                        {workHoursTime}{payTimeframeHours && " / "}{payTimeframeHours}
                                    </Typography>
                                </Tooltip>
                            }
                        </Box>
                    }
                    {
                        /* TODO
                        interClient &&
                        <Typography sx={carHeaderStyle}>
                            {planRow.client.displayName}
                        </Typography>
                        */
                    }

                    <Box sx={{ justifyContent: "space-between", flexDirection: "row", display: "flex" }}>


                        <Box sx={rowStyle}>

                            {
                                planRow.slots.map((slot, key) => {
                                    return <MemoizedSlot
                                        slot={slot}
                                        dragItem={props.dragItem}
                                        key={key}
                                        interClient={props.interClient}
                                        onStartDrag={(e) => props.onStartDrag(e, slot)}
                                        onDragEnd={props.onDragEnd}
                                    />
                                })
                            }

                            <LinearProgress
                                sx={{ width: '100%', my: 0.5 }}
                                variant="buffer"
                                value={normalize(hours.completed)}
                                valueBuffer={normalize(hours.total)}
                            />

                        </Box>

                        <Box
                            sx={{
                                "& .MuiTypography-root": {
                                    fontSize: 12
                                },
                                ml: 2,
                                // minWidth: 200,
                                flexDirection: 'row',
                                display: 'flex',
                                flexWrap: lgBreakPoint ? "nowrap" : "wrap",
                                gap: 1,
                                alignContent: "flex-start",
                            }}
                        >
                            {
                                planRow.vehicle &&
                                <PlanningDriverSelector planRow={planRow} />
                            }
                            <Box
                                sx={{ flexDirection: 'column', minWidth: "100px" }}
                            >
                                {
                                    <Tooltip title={`Lähetä autolle ${planRow.vehicle.id}`}>
                                        <span>
                                            <Button
                                                disabled={!modified}
                                                data-testid="send-vehicle"
                                                data-vehicle={planRow.vehicle.id}
                                                onClick={() => sendCars()}
                                                size="small"
                                                sx={{ px: 0.8, whiteSpace: 'nowrap', width: "4.75rem", height: "1.75rem" }}
                                                variant="contained">
                                                <span style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", gap: "0.3rem", width: "100%", fontSize: "1rem", fontWeight: "bold", lineHeight: "normal" }}>
                                                    {planRow.vehicle.id}
                                                    <SvgIcon component={Send} style={{ fontSize: "1.25rem" }} />
                                                </span>
                                            </Button>
                                        </span>
                                    </Tooltip>
                                }
                                {
                                    <Box
                                        sx={{ p: 0.2, px: 1.5 }}
                                    >

                                    </Box>
                                }

                                <Typography>
                                    Tunnit: {hours.total.toFixed(2)}
                                </Typography>
                                <Typography>
                                    Toteutunut: {hours.completed.toFixed(2)}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            }
        </Box>
    )
}

export default PlanningCarRow;