import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { PlanningJob } from "../api/LogitarApiTypes";
import { useEffect, useState } from "react";
import { format } from "date-fns";

type PlanDetailsPopupProps = {
    open: boolean,
    jobs: PlanningJob[],
    onClose: () => void,
}

export default function PlanDetailsPopup(props: PlanDetailsPopupProps) {
    const [storedDetails, setStoredDetails] = useState<{ [key: number]: string }>({});
    const [detailsEdited, setDetailsEdited] = useState(false);
    const [jobs, setJobs] = useState<PlanningJob[]>([]);

    useEffect(() => {
        setDetailsEdited(false);
        props.jobs.forEach(j => {
            if (storedDetails[j.id] && storedDetails[j.id] !== j.planDetails) setDetailsEdited(true);
            setStoredDetails(prev => ({ ...prev, [j.id]: j.planDetails ? j.planDetails : "" }));
        })

        setJobs(props.jobs)
    }, [props.jobs])


    const getShiftName = (shift: string): string => {
        if (shift === "A") return "Aamu";
        if (shift === "I") return "Ilta";
        return "";
    };

    const getTitle = (): string => {
        if (jobs.length < 1) return '';
        let title = (detailsEdited ? "Kuormien lisätietoja muutettu vuorolle:" : "Kuormien lisätiedot vuorolle:");
        title += " " + format(new Date(jobs[0].date), "dd.MM.yyyy");
        title += " " + getShiftName(jobs[0].shift);
        return title;
    };


    return (
        <Dialog open={props.open} onClose={(_event, reason) => { if (reason !== "backdropClick") props.onClose(); }}>
            <DialogTitle>{getTitle()}</DialogTitle>
            <DialogContent>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                    {props.jobs.sort((a, b) => a.slot - b.slot).map((j, i) =>
                        <Card key={i} variant="outlined" sx={{ backgroundColor: "unset" }}>
                            <CardContent style={{ paddingBottom: "1.125rem" }}>
                                <Typography variant="h6" sx={{ mb: 1 }}>{j.slot + 1} - {j.item.name} - {j.item.cargoLabel}</Typography>
                                <Typography>Lisätieto: {j.planDetails ? j.planDetails : <i>(Poistettu)</i>}</Typography>
                            </CardContent>
                        </Card>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose()} variant="contained">OK</Button>
            </DialogActions>
        </Dialog>
    )
}