import { Box, Collapse, Divider, Link, Paper, Table, TableBody, TableCell, TableHead, TableRow, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useEffect, useState } from "react";
import { TypeBox, extrasList, methodList, typeList } from "./ApiDoc";

/**
 * @typedef {{
 *  name: string,
 *  generics: object,
 *  def: {{
 *      description: string,
 *      path: string,
 *      method: 'GET' | 'POST',
 *      types: {[key: string]: string}
 *  }},
 *  collapsed: boolean,
 *  onCollapse: () => void,
 *  linkOpen?: (name: string) => void,
 *  highlighted?: boolean
 * }} ApiCallsDefProps
 */

/**
 * 
 * @param {{
 *  field: string,
 *  value: string,
 *  description?: string,
 *  generics: object[],
 *  linkOpen?: (name: string) => void,
 *  isURLParam?: boolean
 * }} props 
 */
export function RenderAPIRow(props) {
    const theme = useTheme();

    let k = props.field;
    let e = props.value;
    if(k.charAt(0) === "#" || k.charAt(0) === "@") return null;
    
    let extras = [];
    let valExtras = [];
    let hash = null;
    let cleanValue = e.replace("[]", "");
    let isJSON = false;
    let isEnum = false;
    let desc = props.description;
    if (k.charAt(k.length - 1) === "?") {
        // Optional parameter
        extras.push("optional");
    }
    if (e.charAt(0) === "$") {
        // Find generic
        let g = props.generics.find((x) => x.name === e.replace("[]", ""));
        if (g) {
            if (k === "...") {
                // Spread
                return Object.keys(g.def).flatMap((k) => {
                    return <RenderAPIRow key={k} field={k} value={g.def[k]} generics={props.generics} description={g.attrs["#" + k.replace("?", "")]} linkOpen={props.linkOpen} />
                })
            }
            else {
                // Link
                hash = "#" + g.name.replace("$", "");
            }
            if (g.attrs && g.attrs["@type"] === 'enum') {
                isEnum = true;
            }
            else {
                isJSON = true;
            }
        }
        if (e.includes("[]")) {
            valExtras.push("array");
        }
    }
    if (props.isURLParam) {
        if (isJSON)
            valExtras.push("json");

        if (isEnum)
            valExtras.push("enum");
    }

    return (
        <TableRow>
            <TableCell sx={{ flexDirection: 'row', display: 'flex' }}>
                {k}
                {
                    extras.map((extra, index) => {
                        return <TypeBox key={index} background={theme.palette.mode === "light" ? extrasList[extra].background : extrasList[extra].backgroundDark} name={extrasList[extra].name} />
                    })
                }
            </TableCell>
            <TableCell>
                <Box sx={{ flexDirection: 'row', display: 'flex' }}>
                    {
                        hash ? <Link href={hash} onClick={() => { if (props.linkOpen) props.linkOpen(cleanValue); }} >{e}</Link> : <>{e}</>
                    }
                    {
                        valExtras.map((extra, index) => {
                            return <TypeBox key={index} background={theme.palette.mode === "light" ? extrasList[extra].background : extrasList[extra].backgroundDark} name={extrasList[extra].name} />
                        })
                    }
                </Box>
            </TableCell>
            <TableCell>
                {desc}
            </TableCell>
        </TableRow>
    )
}


/**
 * @brief Call renderer
 * @param {{
 *  name: string,
 *  fields: {[key: string]: string},
 *  generics: object[],
 *  linkOpen?: (name: string) => void
 * }} props 
 */
export function ApiCallRenderer(props) {
    const theme = useTheme();
    return <Box sx={{ p: 1 }}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography variant="h6">
                {props.name}
            </Typography>
            {
                props.name.includes("Response") &&
                <TypeBox background={theme.palette.mode === "light" ? extrasList['json'].background : extrasList['json'].backgroundDark} name={extrasList['json'].name} />
            }
        </Box>
        <Table>
            <colgroup>
                <col style={{ width: '20%' }} />
                <col style={{ width: '20%' }} />
                <col style={{ width: '60%' }} />
            </colgroup>
            <TableHead>
                <TableRow>
                    <TableCell>Field</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Description</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    Object.keys(props.fields).map((k, i) => {
                        return <RenderAPIRow
                            key={"row-" + k}
                            field={k}
                            value={props.fields[k]}
                            description={props.fields["#" + k.replace("?", "")]}
                            generics={props.generics}
                            linkOpen={props.linkOpen}
                            isURLParam={props.name.includes("Request")}
                        />
                    })
                }
            </TableBody>
        </Table>
    </Box>
}

export const GetRequestElement = (field, value, generics) => {
    if (value.charAt(0) === "$") {
        let j = {};
        let isArr = value.includes("[]");
        // Find generic
        let g = generics.find((x) => x.name === value.replace("[]", ""));
        if (g) {
            if (field === "...") {
                // Spread
                Object.keys(g.def).forEach((k) => {
                    j = { ...j, ...GetRequestElement(k, g.def[k], generics) };
                })
            }
            else {
                if (g.attrs && g.attrs["@type"] === 'enum') {
                    let enumStr = "number(";
                    if (Object.keys(g.def).every(k => isNaN(k))) enumStr = "string(";
                    Object.keys(g.def).forEach((k) => {
                        if (enumStr.startsWith("string")) {
                            enumStr += `'${k}' = ${g.def[k]}` + " | ";
                        } else {
                            enumStr += `${k} = ${g.def[k]}` + " | ";
                        }
                    });
                    enumStr = enumStr.slice(0, -3);
                    enumStr += ")";
                    return { [field]: enumStr }
                }
                // Object
                j = {};
                Object.keys(g.def).forEach((k) => {
                    if (g.attrs && g.attrs["@type"] === 'key') {
                        j = { ...j, ...GetRequestElement(`[key: ${k}]`, g.def[k], generics) };
                    } else {
                        j = { ...j, ...GetRequestElement(k, g.def[k], generics) };
                    }
                })
                if (isArr) {
                    j = { [field]: [j] };
                }
                else {
                    j = { [field]: j };
                }
            }
            return j;
        }
    }
    return {
        [field]: value
    }
}

/**
 * 
 * @param {ApiCallsDefProps} props 
 */
export default function ApiCallsDef(props) {

    const [types, setTypes] = useState(props.def.types);

    const [displayType, setDisplayType] = useState(/**@type {'schema'|'request'}*/('schema'));

    const [requestTypes, setRequestTypes] = useState([]);

    const theme = useTheme();

    useEffect(() => {
        if (displayType === 'request') {
            const jt = Object.keys(props.def.types).map((k, i) => {
                const e = props.def.types[k];
                let j = {};
                Object.keys(e).forEach((k) => {
                    if(k.charAt(0) === "#" || k.charAt(0) === "@") return;

                    j = { ...j, ...GetRequestElement(k, e[k], props.generics) }
                });
                return {
                    name: k,
                    request: j
                }
            });

            setRequestTypes(jt);
        }
    }, [displayType])

    let TypeRenderer = ApiCallRenderer;

    return (
        <Paper elevation={2} sx={{ mb: 1 }}>
            <Box onClick={() => props.onCollapse()} sx={{ display: "flex", flexDirection: "row", mb: 1, p: 1, cursor: "pointer", background: props.highlighted ? 'lightyellow' : undefined }}>
                <Typography sx={{ fontWeight: "bold", mr: 2 }} id={props.name}>/{props.name}.php</Typography>
                <Box sx={{ p: 0.5, borderRadius: 1, background: theme.palette.mode === "light" ? methodList[props.def.method].background : methodList[props.def.method].backgroundDark }}>
                    <Typography sx={{ fontSize: '0.7em' }}>{methodList[props.def.method].name}</Typography>
                </Box>
                {!props.collapsed ? <KeyboardArrowUpIcon sx={{ marginLeft: "auto" }} /> : <KeyboardArrowDownIcon sx={{ marginLeft: "auto" }} />}
            </Box>

            <Collapse in={!props.collapsed} >
                <Box sx={{ml: 1}}>
                    <Typography variant="body1">
                        {props.def.description}
                    </Typography>
                </Box>
                <Box sx={{mx: 1, p: 1, borderRadius: 2, background: '#333', color: 'primary.contrastText'}}>
                    <Typography variant="body1">
                        {props.def.example}
                    </Typography>
                </Box>
                <Divider sx={{ m: 1 }} />
                <ToggleButtonGroup
                    size="small"
                    value={displayType}
                    exclusive
                    onChange={(e, v) => { if (v) setDisplayType(v) }}
                    sx={{ ml: 1 }}
                >
                    <ToggleButton value="schema">Schema</ToggleButton>
                    <ToggleButton value="request">REQUEST</ToggleButton>
                </ToggleButtonGroup>
                {
                    displayType === 'schema' &&
                    Object.keys(props.def.types).map((k, i) => {
                        const e = props.def.types[k];
                        return <TypeRenderer key={i} generics={props.generics} name={k} fields={e} linkOpen={props.linkOpen} />
                    })
                }
                {
                    displayType === 'request' &&
                    requestTypes.map((e, i) => {
                        return <Box key={i} sx={{ p: 1 }}>
                            <Box sx={{ display: "flex", flexDirection: "row" }}>
                                <Typography variant="h6">{e.name}</Typography>
                                {
                                    e.name.includes("Response") &&
                                    <TypeBox background={theme.palette.mode === "light" ? extrasList['json'].background : extrasList['json'].backgroundDark} name={extrasList['json'].name} />
                                }
                            </Box>
                            {
                                e.name.includes("Request") &&
                                Object.keys(e.request).map((k, i) => {
                                    return <pre
                                        style={{ margin: 0, padding: 0 }}
                                    >{k}={(typeof e.request[k] === 'object') ? JSON.stringify(e.request[k], null, 2) : e.request[k]}{i + 1 === Object.keys(e.request).length ? "" : "&"}</pre>
                                })
                            }
                            {
                                e.name.includes("Response") &&
                                <pre>{JSON.stringify(e.request, null, 2)}</pre>
                            }
                        </Box>
                    })
                }
            </Collapse>
        </Paper>
    )
}